<template>
     <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12">
                <div class="d-flex flex-wrap flex-wrap align-items-center justify-content-between mb-4">
                    <div>
                        <h4 class="mb-3">{{title}}</h4>
                        <p class="mb-0">{{desc1}}<br> {{desc2}}</p>
                        <p v-b-modal.modal-3 variant="link" class="mb-0"><u>발주서내역 수집 방법</u></p>
                            <b-modal id="modal-3" size="xl" title="판매내역 수집 방법" ok-title="Save Changes" cancel-title="Close">
                                <p><a href="https://chrome.google.com/webstore/detail/couplus/bogokmehpkdjafnlhjhinfgmeljdgmgh?hl=ko&authuser=0" target="_blank"><u>구글웹스토어</u></a>에 접속해서 "COUPLUS"를 다운받은 후 <b style="color:red"><u>"Supplier"에 로그인한 상태에서</u></b> 발주서 수집 버튼을 클릭해주세요. 발주서 수집이 끝날때까지 창을 닫거나 조정하시면 안됩니다.</p>
                                <img  style="max-width:100%;" :src="require('../../../assets/images/small/extension_polist.png')">
                            </b-modal>
                    </div>
                </div>

                
                <card>
                     <div class="p-3">
                        <form  @submit.prevent="submit" >
                            <div class="row">
                                                             
                                <div class="col-md-12">
                                    <div class="custom-control custom-radio custom-control-inline">
                                        <input type="radio" id="customRadio7" name="customRadio-2" class="custom-control-input" v-model="selectdate" value="today" @change="changedate()">
                                        <label class="custom-control-label" for="customRadio7">오늘</label>
                                    </div>
                                    <div class="custom-control custom-radio custom-control-inline">
                                        <input type="radio" id="customRadio8" name="customRadio-2" class="custom-control-input" v-model="selectdate" value="week" @change="changedate()">
                                        <label class="custom-control-label" for="customRadio8">최근7일</label>
                                    </div>
                                    <div class="custom-control custom-radio custom-control-inline">
                                        <input type="radio" id="customRadio9" name="customRadio-2" class="custom-control-input" v-model="selectdate" value="month" @change="changedate()">
                                        <label class="custom-control-label" for="customRadio9">최근1개월</label>
                                    </div>
                                    <!-- <div class="custom-control custom-switch custom-control-inline">
                                        <input type="checkbox" class="custom-control-input" id="customSwitch2" v-model="checksstock">
                                        <label class="custom-control-label" for="customSwitch2">사입제품 포함 발주서만 보기</label>
                                    </div> -->
                                </div>
                                <div class="col-md-12 mt-2">
                                  <b-input-group>
                                        <select class="custom-select col-md-2" style="height:40px" v-model="selectoption">
                                            <option>발주일</option>
                                            <option>입고예정일</option>
                                        </select>
                                        <b-form-datepicker id="example-datepicker1" size="sm" v-model="startdate" class="mb-2"></b-form-datepicker>
                                        <p>~</p>
                                        <b-form-datepicker id="example-datepicker2" size="sm" v-model="enddate" class="mb-2"></b-form-datepicker>
                                        
                                    </b-input-group>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <input type="text" class="form-control" placeholder="발주번호" v-model="발주번호">
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <input type="text" class="form-control" placeholder="SKU ID" v-model="SKUID">
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <input type="text" class="form-control" placeholder="바코드" v-model="바코드">
                                    </div>
                                </div>                                                                
                            </div>                            
                            <button type="button" class="btn btn-primary mr-2 mt-1" @click="search(true)">발주내역검색</button>
                            <button type="button" class="btn btn-secondary mr-2 mt-1" @click="search(false)">SKU내역검색</button>
                            <img v-if="showspinner"  style="max-width:100%;" :src="require('../../../assets/images/small/spinner.gif')">
                        </form>
                    </div>
                </card>

                <div class="col-lg-12" v-if="showlist">
                    <div class="row mb-2">
                        <div class="badge bg-primary text-wrap mr-2 fw-bold" style="font-size:100%" v-if="showlist">
                            총 발주금액 : {{totalpo.toLocaleString()}}
                        </div>
                        <div class="badge bg-secondary text-wrap fw-bold" style="font-size:100%" v-if="showlist">
                            발주취소금액 : {{totalcancel.toLocaleString()}}
                        </div>
                    </div>
                    <div class="row mt-2">
                        <b-button variant="outline-primary" class="mb-1 mr-2" @click="showmototal">
                        전체발주 <b-badge variant="primary" class="ml-2">{{mototal}}</b-badge>
                        </b-button>
                        <b-button variant="outline-warning" class="mb-1 mr-2" @click="showmoconfirm">
                        발주확정<b-badge variant="warning" class="ml-2">{{moconfirm}}</b-badge>
                        </b-button>
                        <b-button variant="outline-warning" class="mb-1 mr-2" @click="showmocancel">
                        발주취소<b-badge variant="warning" class="ml-2">{{mocancel}}</b-badge>
                        </b-button>                        
                        <b-button variant="outline-success" class="mb-1 mr-2" @click="showmostock">
                        미배치재고<b-badge variant="success" class="ml-2">{{mostock}}</b-badge>
                        </b-button>
                        <b-button variant="outline-danger" class="mb-1 mr-2" @click="showmoaddstock">
                        부족수량<b-badge variant="danger" class="ml-2">{{moaddstock}}</b-badge>
                        </b-button>
                        <b-button variant="outline-warning" class="mb-1 mr-2" @click="showmoastock">
                        출고배치<b-badge variant="warning" class="ml-2">{{moastock}}</b-badge>
                        </b-button>                         
                        <b-button variant="outline-danger" class="mb-1 mr-2" @click="showmopstock">
                        구매완료<b-badge variant="danger" class="ml-2">{{mopstock}}</b-badge>
                        </b-button> 
                        <b-button variant="outline-warning" class="mb-1 mr-2" @click="showmorstock">
                        회송예정<b-badge variant="warning" class="ml-2">{{morstock}}</b-badge>
                        </b-button>
                        <b-button variant="outline-danger" class="mb-1 mr-2" @click="showmosstock">
                        사입재고<b-badge variant="danger" class="ml-2">{{mosstock}}</b-badge>
                        </b-button>                         
                        <b-button variant="outline-secondary" class="mb-1 mr-2" @click="minusprofitlist" v-b-tooltip.bottom="'로켓상품의 판매가가 자동으로 조정되다가 공급가보다 낮아질 경우 500원 이하의 발주서가 생성될 수 있습니다. 500원 이하의 발주서를 꼭 확인해 주세요'">
                        마이너스 발주<b-badge variant="secondary" class="ml-2">{{minusprofit}}</b-badge>
                        </b-button>                                                                                       
                    </div> 
                </div>


               

            <div class="col-lg-12" v-if="showlist">
                <div class="table-responsive rounded mb-3">
                    <!-- <div class = "float-left mb-4">
                        <div class="custom-control custom-radio custom-radio-color-checked custom-control-inline">
                            <input v-model="picked1" @change="MenuUpdate1" type="radio" id="customRadio-11" name="customRadio-101" class="custom-control-input bg-primary" value="radio1">
                            <label class="custom-control-label" for="customRadio-11"> 발주내역 </label>
                        </div>
                        <div class="custom-control custom-radio custom-radio-color-checked custom-control-inline">
                            <input v-model="picked1" @change="MenuUpdate1" type="radio" id="customRadio-21" name="customRadio-101" class="custom-control-input bg-success" value="radio2">
                            <label class="custom-control-label" for="customRadio-21"> 발주SKU내역 </label>
                        </div>
                    </div> -->
                    <div class = "float-right mb-4">
                        <div class="custom-control custom-radio custom-radio-color-checked custom-control-inline">
                            <input v-model="picked" @change="MenuUpdate" type="radio" id="customRadio-1" name="customRadio-10" class="custom-control-input bg-primary" value="radio1">
                            <label class="custom-control-label" for="customRadio-1"> Default </label>
                        </div>
                        <div class="custom-control custom-radio custom-radio-color-checked custom-control-inline">
                            <input v-model="picked" @change="MenuUpdate" type="radio" id="customRadio-2" name="customRadio-10" class="custom-control-input bg-success" value="radio2">
                            <label class="custom-control-label" for="customRadio-2"> Detail </label>
                        </div>
                    </div>
                    <tableList v-if="showtable">             
                        <template v-slot:header >
                            <tr class="ligth ligth-data">
                                <!-- <th>
                                    <div class="checkbox d-inline-block">
                                        <input type="checkbox" class="checkbox-input" id="checkbox1">
                                        <label for="checkbox1" class="mb-0"></label>
                                    </div>
                                </th> -->
                                <th>발주번호</th> 
                                <th>진행현황</th>
                                <th v-for="menu in menuint" :key="menu">{{menu}}</th>
                            </tr>
                        </template>
                         <template v-slot:body >
                            <tr v-for="(polist,index) in polists" :key="index" v-b-modal="modalID(index)">
                                <!-- <td>
                                    <div class="checkbox d-inline-block">
                                        <input type="checkbox" class="checkbox-input" id="checkbox2">
                                        <label for="checkbox2" class="mb-0"></label>
                                    </div>
                                </td> -->
                                <td>{{polist.발주번호}}</td>
                                <td>{{polist.진행현황}}</td>
                                    
                                    <b-modal modal-class="po-modal-class" :id="'modal'+index" scrollable title="발주세부내역" ok-title="OK" cancel-title="Close">
                                        <div class="table-responsive">
                                            <table id="datatable" class="table data-table table-striped dataTable" >
                                            <thead>
                                                <tr class="ligth">
                                                    <th style="min-width:100px;">SKU ID</th>
                                                    <th style="min-width:200px;">상품명</th>
                                                    <th v-for="m in menupodetail" :key="m">{{m}}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="a,i in polist.발주세부내역" :key="i" role="row" v-bind:style="styleobj(a)">
                                                    <td>
                                                        <div class="d-flex align-items-center">
                                                            <img :src="a.IMGURL" class="img-fluid rounded avatar-80 mr-3" alt="image">
                                                            <div>{{a.SKUID}}</div>
                                                        </div>
                                                    </td>
                                                    <td>{{a["상품명"]}}</td>
                                                    <td v-for="m in menupodetail" :key="m">{{a[m]}}</td>
                                                </tr>
                                            </tbody>
                                            </table>
                                        </div>        
                                    </b-modal>
                                <td v-for="menu in menuint" :key="menu" style="min-width:110px;max-width:200px;overflow:hidden;text-overflow: ellipsis;">{{polist[menu]}}</td>
                            </tr>
                        </template>
                    </tableList>

                    <tableList v-if="showdetailtable">             
                        <template v-slot:header >
                            <tr class="ligth ligth-data">
                                <!-- <th>
                                    <div class="checkbox d-inline-block">
                                        <input type="checkbox" class="checkbox-input" id="checkbox1">
                                        <label for="checkbox1" class="mb-0"></label>
                                    </div>
                                </th> -->
                                <th>발주번호</th> 
                                <th>진행현황</th>
                                <th>발주센터</th>
                                <th>입고예정일</th>
                                <th style="min-width:100px;">SKU ID</th>
                                <th style="min-width:200px;">상품명</th>
                                <th v-for="m in menupodetail" :key="m">{{m}}</th>
                            </tr>
                        </template>
                         <template v-slot:body >
                            <template v-for="polist in polists">
                                <tr v-for="(a,index) in polist.발주세부내역" :key="`${polist.발주번호}-${index}`" role="row" v-bind:style="styleobj(a)">
                                    <td>{{polist.발주번호}}</td>
                                    <td>{{polist.진행현황}}</td>
                                    <td>{{polist.발주센터}}</td>
                                    <td>{{polist.입고예정일}}</td>
                                    <td>
                                        <div class="d-flex align-items-center">
                                            <img :src="a.IMGURL" class="img-fluid rounded avatar-80 mr-3" alt="image">
                                            <div>{{a.SKUID}}</div>
                                        </div>
                                    </td>
                                    <td>{{a["상품명"]}}</td>
                                    <td v-for="m in menupodetail" :key="m">{{a[m]}}</td>
                                </tr>
                            </template>
                        </template>
                    </tableList>
                </div>
                <button type="button" class="btn btn-primary mr-2 mb-4" @click="downloadpolist">발주서 다운로드</button>
                <button type="button" class="btn btn-secondary mr-2 mb-4" @click="openreqpurchase">구매요청</button>
                <!-- <button type="button" class="btn btn-warning mr-2 mb-4" @click="totaldatesinit">발주 및 재고배치 확정</button>
                <button type="button" class="btn btn-success mr-2 mb-4" @click="arrangelist">발주 및 재고배치 확정내역</button> -->
                    <b-modal id="reqpurchase" size="xl" scrollable title="구매대행요청" ok-title="구매대행요청추가" cancel-title="취소" class="detail-modal" @ok="requestpurchase($event)">
                        <div class="table-responsive">
                            <div class="row border-bottom border-primary mt- p-3 pr-5 d-flex justify-content-center">
                                <div class="text-center" style="width:10% !important;">발주정보</div>
                                <div ckass="text-center" style="width:40% !important;">SKU정보</div>
                                <div class="text-center" style="width:5% !important;" v-for="m in menureqth" :key="m">{{m}}</div>
                                <div class="text-center" style="width:15% !important;">구매요청수량</div>
                                <div class="text-center" style="width:5% !important;">삭제</div>
                            </div>
                            <table class="mt-3 table data-table table-striped dataTable fixed-header">
                            <tbody>
                                <tr v-for="(a,index) in reqlists" :key="index" role="row" v-bind:style="styleobj(a)">
                                    <td class="text-center" style="width:10% !important;">{{a.발주번호}}<br>
                                        <p class="mb-0"><small>{{a.발주센터}}</small></p>
                                        <p class="mb-0"><small>{{a.입고예정일}}</small></p>
                                    </td>
                                    <td style="width:40% !important;">
                                        <div class="d-flex align-items-center">
                                            <img :src="a.IMGURL" class="img-fluid rounded avatar-80 mr-3" alt="image">
                                            <div>
                                                <p class="mb-0">{{a.상품명}}</p>
                                                <p class="mb-0"><small>{{a.SKUID}}</small></p>
                                                <p class="mb-0"><small>{{a.바코드}}</small></p>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="text-center" style="width:5% !important;" v-for="m in menureq" :key="m">{{a[m]}}</td>
                                    <td class="text-center" style="width:15% !important;"><input :min="0" type="number" class="form-control text-center" aria-label="" v-model="a.구매요청수량"></td>
                                    <td class="text-center" style="width:5% !important;">
                                        <div>
                                            <!-- <a class="badge bg-success mr-2" data-toggle="tooltip" v-b-tooltip.top title="Reset"
                                                href="javascript:void(0)" @click="resetitem(i)"><i class="ri-pencil-line mr-0"></i></a>													 -->
                                            <a class="badge bg-warning mr-2" data-toggle="tooltip" v-b-tooltip.top title="Delete"
                                                href="javascript:void(0)" @click="deletereqitem(index)"><i class="ri-delete-bin-line mr-0"></i></a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            </table>
                        </div> 
                    </b-modal>

                    <b-modal id="waitreqpurchase" size="sm"  hide-footer>
                        <div class="text-center">
                            구매요청 내역을 저장 중입니다. 잠시만 기다려 주세요.
                        </div>
                        <div class=text-center>
                            <img style="max-width:100%;" :src="require('../../../assets/images/small/spinner.gif')">
                        </div>
                        
                    </b-modal>


                                    <b-modal id="confirmmodal" size="sm" scrollable title="발주 및 재고배치 확정" ok-title="발주확정 양식 다운로드" cancel-title="닫기" class="detail-modal" @ok="downloadpoconfirm">
                                        <div class="row">

                                            <div class="col-md-12">
                                                <h6 class="mt-2 mb-1">[ 예상박스수량 ]<small>&nbsp;&nbsp;&nbsp;※상품DB에 '1박스인입수량'을 업데이트하면 예상박스수량을 확인하실 수 있습니다.</small></h6>
                                            </div>
                                            <div class="col-md-12">
                                                <div class="custom-control custom-control-inline">
                                                    <p v-b-tooltip.bottom="'출고되는 최대박스 사이즈 : 기준박스부피에 따라 센터별 예상박스수량이 달라집니다.'">기준박스부피</p>
                                                    <label class="custom-control-label" for="customRadio24">가로(mm)</label>
                                                    <input type="number" class="form-control" placeholder="길이(mm)" v-model="stdlength" @change="changeboxvolume">
                                                    <label class="custom-control-label" for="customRadio24">세로(mm)</label>
                                                    <input type="number" class="form-control" placeholder="너비(mm)" v-model="stdwidth" @change="changeboxvolume">
                                                    <label class="custom-control-label" for="customRadio24">높이(mm)</label>
                                                    <input type="number" class="form-control" placeholder="높이(mm)" v-model="stdheight" @change="changeboxvolume">
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <div class="table-responsive">
                                                    <table id="datatable" class="table data-table table-striped dataTable" >
                                                        <thead>
                                                            <tr class="ligth text-center">
                                                                <th rowspan="2">입고예정일</th>
                                                                <th rowspan="2" class="border-right">발주센터</th>
                                                                <th class="border-right">쉽먼트 박스수량<div>( 확정전 + 확정후 )</div></th>
                                                                <th>밀크런 박스수량<div>( 확정전 + 확정후 )</div></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-b-modal="modalmulti2(index)" class="text-center" v-for="(list,index) in boxlists" :key="index">
                                                                <td>{{list.입고예정일}}</td>
                                                                <td class="border-right">{{list.발주센터}}</td>
                                                                <td :class="list.쉽먼트박스수량 > 9 ? 'btn-secondary btn-lg text-white font-weight-bold' : ''">
                                                                    {{list.쉽먼트박스수량 == 0 ? '' : list.총박스수량?.toFixed(2)}}  ( {{list.쉽먼트박스수량_확정전 == 0 ? 0 : list.쉽먼트박스수량_확정전?.toFixed(2)}} + {{list.쉽먼트박스수량_확정후 == 0 ? 0 : list.쉽먼트박스수량_확정후?.toFixed(2)}} )
                                                                </td>
                                                                <td :class="list.밀크런박스수량 > 9 ? 'btn-secondary btn-lg text-white font-weight-bold' : ''">
                                                                    {{list.밀크런박스수량 == 0 ? '' : list.총박스수량?.toFixed(2)}}  ( {{list.밀크런박스수량_확정전 == 0 ? 0 : list.밀크런박스수량_확정전?.toFixed(2)}} + {{list.밀크런박스수량_확정후 == 0 ? 0 : list.밀크런박스수량_확정후?.toFixed(2)}} )
                                                                </td>

                                            <b-modal :id="'modal-multi-2'+index" title="발주상세내역" ok-title="저장" cancel-title="취소" @cancel="initpolist(list.boxdetail)">
                                                <div class="col-md-12">
                                                    <h6 class="mt-2 mb-1">[ 발주확정 기준 ]</h6>              
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" id="customRadio24" name="customRadio-3" class="custom-control-input" v-model="list.confirmpo" value="all">
                                                        <label class="custom-control-label" for="customRadio24">전체</label>
                                                    </div>
                                                    <div class="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" id="customRadio22" name="customRadio-3" class="custom-control-input" v-model="list.confirmpo" value="stockkr">
                                                        <label class="custom-control-label" for="customRadio22">국내재고</label>
                                                    </div>
                                                    <div class="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" id="customRadio23" name="customRadio-3" class="custom-control-input" v-model="list.confirmpo" value="stocktotal">
                                                        <label class="custom-control-label" for="customRadio23">국내재고 + 해외재고</label>
                                                    </div>
                                                    <div class="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" id="customRadio21" name="customRadio-3" class="custom-control-input" v-model="list.confirmpo" value="total">
                                                        <label class="custom-control-label" for="customRadio21">국내재고 + 해외재고 + 구매재고</label>
                                                    </div>
                                                </div>

                                                <button type="button" class="btn btn-success mr-2 mb-4" @click="downloadstocklists">엑셀다운로드</button>
                                                <button type="button" class="btn btn-secondary mr-2 mb-4" @click="changeinfo(list.boxdetail)">변경</button>
                                                <!-- <button type="button" class="btn btn-primary mr-2 mb-4" @click="deleteitem">삭제</button> -->
                                                <!-- <button type="button" class="btn btn-secondary mr-2 mb-4" @click="changeshipment">쉽먼트 ↔ 밀크런</button> -->
                                                <button type="button" class="btn btn-primary mr-2 mb-4" @click="reqpurchase">구매대행요청</button>
                                                <!-- <button type="button" class="btn btn-secondary mr-2 mb-4" @click="confirmarrange">배치확정</button> -->
                                                <!-- <button type="button" class="btn btn-success mr-2 mb-4" @click="downloadconfirmpo">발주확정 다운로드</button> -->

                                                <tab-nav :tabs="true" id="myTab-1">
                                                    <tab-nav-items :active="true" id="shipment-tab" ariaControls="shipment" role="tab" :ariaSelected="true" title="쉽먼트" />
                                                    <tab-nav-items :active="false" id="milkrun-tab" ariaControls="milkrun" role="tab" :ariaSelected="false" title="밀크런/트럭" />
                                                </tab-nav>
                                                
                                                <tab-content id="myTabContent">
                                                    <tab-content-item :active="true" id="shipment" aria-labelled-by="shipment-tab">
                                                        <div class="card">
                                                            <div class="table-responsive" style="overflow-y:auto">
                                                                <table id="datatable" class="table data-table table-striped dataTable">
                                                                    <thead>
                                                                        <tr class="ligth ligth-data">
                                                                            <th>
                                                                                <div class="checkbox d-inline-block">
                                                                                    <input type="checkbox" class="checkbox-input" id="checkbox1" @change="checkallbox(list,'shipment')" v-model="list.shipmentcheck">
                                                                                    <label for="checkbox1" class="mb-0"></label>
                                                                                </div>
                                                                            </th>
                                                                            <!-- <th>발주운송</th> -->
                                                                            <th style="font-size:90%">입고예정일</th>
                                                                            <th style="font-size:90%">발주센터</th>
                                                                            <th style="font-size:90%">발주번호</th>
                                                                            <th style="font-size:90%">발주상태</th>
                                                                            <th style="font-size:90%">발주운송</th>

                                                                            <th style="font-size:90%">상품정보</th>
                                                                            <th style="font-size:90%">발주수량</th>
                                                                            <th style="font-size:90%">확정수량</th>

                                                                            <th style="font-size:90%">국내재고</th>
                                                                            <th style="font-size:90%">해외재고</th>
                                                                            <th style="font-size:90%">구매재고</th>

                                                                            <th style="font-size:90%">부피대비매출</th>
                                                                            <th style="font-size:90%">1박스인입수량</th>
                                                                            <th style="font-size:90%">누적박스수량</th>
                                                                            <th style="font-size:90%">배치박스번호</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr v-for="(stocklist,idx) in list.boxdetail" :key="idx">
                                                                            <td>
                                                                                <div class="checkbox d-inline-block">
                                                                                    <input type="checkbox" class="checkbox-input" id="checkbox2" v-model="stocklist.checked">
                                                                                    <label for="checkbox2" class="mb-0"></label>
                                                                                </div>
                                                                            </td>
                                                                            <td>{{stocklist.입고예정일}}</td>
                                                                            <td>{{stocklist.발주센터}}</td>
                                                                            <td>{{stocklist.발주번호}}</td>
                                                                            <td>{{stocklist.발주상태}}</td>
                                                                            <td>
                                                                                <div :class="stocklist.발주운송 == '쉽먼트' ? 'badge bg-success text-wrap mr-2 fw-bold' : 'badge bg-secondary text-wrap mr-2 fw-bold'">
                                                                                    {{stocklist.발주운송}}
                                                                                </div>
                                                                            </td>

                                                                            <td style="min-width:300px;font-size:80%;">
                                                                                <div class="d-flex align-items-center">
                                                                                        <img :src="stocklist.등록이미지" class="img-fluid rounded avatar-80 mr-3" alt="image">
                                                                                        <div>
                                                                                        {{stocklist.상품명}}
                                                                                        <p class="mb-0"><small>{{stocklist.바코드}}</small></p>
                                                                                        <p class="mb-0"><small>{{stocklist.SKUID}}</small></p>
                                                                                        </div>
                                                                                </div>
                                                                            </td>
                                                                            <td>{{stocklist.발주수량}}</td>
                                                                            <td>{{stocklist.확정수량}}</td>

                                                                            <td>{{stocklist.국내재고수량 == 0 ? '' : stocklist.국내재고수량}}</td>
                                                                            <td>{{stocklist.해외재고수량 == 0 ? '' : stocklist.해외재고수량}}</td>
                                                                            <td>{{stocklist.구매재고수량 == 0 ? '' : stocklist.구매재고수량}}</td>

                                                                            <td>{{stocklist.부피대비수익률 == 0 ? '' : stocklist.부피대비수익률.toLocaleString('ko-KR')}}</td>
                                                                            <td @click="stocklist.showchangedb = false">
                                                                                <div v-if="stocklist.showchangedb">
                                                                                    {{stocklist['1박스인입수량'] == 0 ? '' : stocklist['1박스인입수량']}}
                                                                                </div>
                                                                                <div v-else>
                                                                                    <div class="form-group">
                                                                                        <input type="number" class="form-control" placeholder="1박스인입수량" v-model="stocklist['1박스인입수량']" @change="changeboxqty(stocklist)" @keyup.enter="stocklist.showchangedb = !stocklist.showchangedb">
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                            <td>{{stocklist.누적박스수량 == 0 ? '' : stocklist.누적박스수량?.toFixed(2)}}</td>
                                                                            <td>{{stocklist.출고박스번호 == 0 ? '' : stocklist.출고박스번호}}</td>

                                                                                        <b-modal id="modal-multi-3" title="변경" ok-only>
                                                                                            <div class="row">
                                                                                                <div class="col-md-12">
                                                                                                    <h6 class="mt-4 mb-1">[ 쉽먼트 / 밀크런]</h6>              
                                                                                                </div>
                                                                                                <div class="col-md-12">
                                                                                                    <div class="custom-control custom-radio custom-control-inline">
                                                                                                        <input type="radio" id="customRadio25" name="customRadio-4" class="custom-control-input" v-model="stocklist.logistics" value="쉽먼트">
                                                                                                        <label class="custom-control-label" for="customRadio25">쉽먼트</label>
                                                                                                    </div>
                                                                                                    <div class="custom-control custom-radio custom-control-inline">
                                                                                                        <input type="radio" id="customRadio26" name="customRadio-4" class="custom-control-input" v-model="stocklist.logistics" value="밀크런">
                                                                                                        <label class="custom-control-label" for="customRadio26">밀크런</label>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div class="col-md-12">
                                                                                                    <h6 class="mt-4 mb-1">[ 납품부족 사유 ]</h6>
                                                                                                </div>
                                                                                                <div class="col-md-12">
                                                                                                    <div class="input-group mb-4">
                                                                                                        <input type="text" class="form-control" placeholder="납품부족사유선택" aria-label="" v-model="stocklist.reason" style="text-align:center;">
                                                                                                        <div class="input-group-append">
                                                                                                            <b-dropdown class="my-class" variant="primary" type="button" text="">
                                                                                                                <b-dropdown-item @click="reasonchange(b)" v-for="b,j in reasons" :key="j">{{b}}</b-dropdown-item>
                                                                                                            </b-dropdown>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div class="col-md-2 mt-4">
                                                                                                    <div class="form-group">
                                                                                                        <h6 for="confirmqty" class="mb-0">[ 확정수량 ]</h6>
                                                                                                        <input id="confirmqty" type="number" class="form-control" placeholder="확정수량" v-model="stocklist.확정수량">
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div class="col-md-2 mt-4">
                                                                                                    <div class="form-group">
                                                                                                        <h6 for="boxinput" class="mb-0">[ 1박스 인입수량 ]</h6>
                                                                                                        <input id="boxinput" type="number" class="form-control" placeholder="1박스인입수량" v-model="stocklist['1박스인입수량']">
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div class="col-md-2 mt-4">
                                                                                                    <div class="form-group">
                                                                                                        <h6 for="aboxnum" class="mb-0">[ 배치박스번호 ]</h6>
                                                                                                        <input id="aboxnum" type="number" class="form-control" placeholder="배치박스번호" v-model="stocklist.출고박스번호">
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div class="col-md-12 mt-4">
                                                                                                    <div class="custom-control custom-switch custom-control-inline">
                                                                                                        <input type="checkbox" class="custom-control-input" id="customSwitch2" v-model="rearrange">
                                                                                                        <label class="custom-control-label" for="customSwitch2">저장 후 전체박스 재 정렬</label>
                                                                                                    </div>          
                                                                                                </div>
                                                                                                                                        
                                                                                            </div>
                                                                                        </b-modal>



                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </tab-content-item>
                                                    <tab-content-item :active="false" id="milkrun" aria-labelled-by="milkrun-tab">
                                                        <div class="card">
                                                            <div class="table-responsive" style="overflow-y:auto">
                                                                <table id="datatable" class="table data-table table-striped dataTable">
                                                                    <thead>
                                                                        <tr class="ligth ligth-data">
                                                                            <th>
                                                                                <div class="checkbox d-inline-block">
                                                                                    <input type="checkbox" class="checkbox-input" id="checkbox1" @change="checkallbox(list,'milkrun')" v-model="list.milkruncheck">
                                                                                    <label for="checkbox1" class="mb-0"></label>
                                                                                </div>
                                                                            </th>
                                                                            <!-- <th>발주운송</th> -->
                                                                            <th style="font-size:90%">입고예정일</th>
                                                                            <th style="font-size:90%">발주센터</th>
                                                                            <th style="font-size:90%">발주번호</th>
                                                                            <th style="font-size:90%">발주상태</th>
                                                                            <th style="font-size:90%">발주운송</th>

                                                                            <th style="font-size:90%">상품정보</th>
                                                                            <th style="font-size:90%">발주수량</th>
                                                                            <th style="font-size:90%">확정수량</th>

                                                                            <th style="font-size:90%">국내재고</th>
                                                                            <th style="font-size:90%">해외재고</th>
                                                                            <th style="font-size:90%">구매재고</th>

                                                                            <th style="font-size:90%">부피대비매출</th>
                                                                            <th style="font-size:90%">1박스인입수량</th>
                                                                            <th style="font-size:90%">누적박스수량</th>
                                                                            <th style="font-size:90%">배치박스번호</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr v-for="(stocklist,index) in list.milkrundetail" :key="index">
                                                                            <td>
                                                                                <div class="checkbox d-inline-block">
                                                                                    <input type="checkbox" class="checkbox-input" id="checkbox2" v-model="stocklist.checked">
                                                                                    <label for="checkbox2" class="mb-0"></label>
                                                                                </div>
                                                                            </td>
                                                                            <td>{{stocklist.입고예정일}}</td>
                                                                            <td>{{stocklist.발주센터}}</td>
                                                                            <td>{{stocklist.발주번호}}</td>
                                                                            <td>{{stocklist.발주상태}}</td>
                                                                            <td>
                                                                                <div :class="stocklist.발주운송 == '쉽먼트' ? 'badge bg-success text-wrap mr-2 fw-bold' : 'badge bg-secondary text-wrap mr-2 fw-bold'">
                                                                                    {{stocklist.발주운송}}
                                                                                </div>
                                                                            </td>

                                                                            <td style="min-width:300px;font-size:80%;">
                                                                                <div class="d-flex align-items-center">
                                                                                        <img :src="stocklist.등록이미지" class="img-fluid rounded avatar-80 mr-3" alt="image">
                                                                                        <div>
                                                                                        {{stocklist.상품명}}
                                                                                        <p class="mb-0"><small>{{stocklist.바코드}}</small></p>
                                                                                        <p class="mb-0"><small>{{stocklist.SKUID}}</small></p>
                                                                                        </div>
                                                                                </div>
                                                                            </td>
                                                                            <td>{{stocklist.발주수량}}</td>
                                                                            <td>{{stocklist.확정수량}}</td>

                                                                            <td>{{stocklist.국내재고수량 == 0 ? '' : stocklist.국내재고수량}}</td>
                                                                            <td>{{stocklist.해외재고수량 == 0 ? '' : stocklist.해외재고수량}}</td>
                                                                            <td>{{stocklist.구매재고수량 == 0 ? '' : stocklist.구매재고수량}}</td>

                                                                            <td>{{stocklist.부피대비수익률 == 0 ? '' : stocklist.부피대비수익률.toLocaleString('ko-KR')}}</td>
                                                                            <td @click="stocklist.showchangedb = false">
                                                                                <div v-if="stocklist.showchangedb">
                                                                                    {{stocklist['1박스인입수량'] == 0 ? '' : stocklist['1박스인입수량']}}
                                                                                </div>
                                                                                <div v-else>
                                                                                    <div class="form-group">
                                                                                        <input type="number" class="form-control" placeholder="1박스인입수량" v-model="stocklist['1박스인입수량']" @change="changeboxqty(stocklist)" @keyup.enter="stocklist.showchangedb = !stocklist.showchangedb">
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                            <td>{{stocklist.누적박스수량 == 0 ? '' : stocklist.누적박스수량?.toFixed(2)}}</td>
                                                                            <td>{{stocklist.출고박스번호 == 0 ? '' : stocklist.출고박스번호}}</td>


                                                                                        <b-modal id="modal-multi-3" title="변경" ok-only>
                                                                                            <div class="row">
                                                                                                <div class="col-md-12">
                                                                                                    <h6 class="mt-4 mb-1">[ 쉽먼트 / 밀크런]</h6>              
                                                                                                </div>
                                                                                                <div class="col-md-12">
                                                                                                    <div class="custom-control custom-radio custom-control-inline">
                                                                                                        <input type="radio" id="customRadio25" name="customRadio-4" class="custom-control-input" v-model="stocklist.logistics" value="쉽먼트">
                                                                                                        <label class="custom-control-label" for="customRadio25">쉽먼트</label>
                                                                                                    </div>
                                                                                                    <div class="custom-control custom-radio custom-control-inline">
                                                                                                        <input type="radio" id="customRadio26" name="customRadio-4" class="custom-control-input" v-model="stocklist.logistics" value="밀크런">
                                                                                                        <label class="custom-control-label" for="customRadio26">밀크런</label>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div class="col-md-12">
                                                                                                    <h6 class="mt-4 mb-1">[ 납품부족 사유 ]</h6>
                                                                                                </div>
                                                                                                <div class="col-md-12">
                                                                                                    <div class="input-group mb-4">
                                                                                                        <input type="text" class="form-control" placeholder="납품부족사유선택" aria-label="" v-model="stocklist.reason" style="text-align:center;">
                                                                                                        <div class="input-group-append">
                                                                                                            <b-dropdown class="my-class" variant="primary" type="button" text="">
                                                                                                                <b-dropdown-item @click="reasonchange(b)" v-for="b,j in reasons" :key="j">{{b}}</b-dropdown-item>
                                                                                                            </b-dropdown>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div class="col-md-2 mt-4">
                                                                                                    <div class="form-group">
                                                                                                        <h6 for="confirmqty" class="mb-0">[ 확정수량 ]</h6>
                                                                                                        <input id="confirmqty" type="number" class="form-control" placeholder="확정수량" v-model="stocklist.확정수량">
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div class="col-md-2 mt-4">
                                                                                                    <div class="form-group">
                                                                                                        <h6 for="boxinput" class="mb-0">[ 1박스 인입수량 ]</h6>
                                                                                                        <input id="boxinput" type="number" class="form-control" placeholder="1박스인입수량" v-model="stocklist['1박스인입수량']">
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div class="col-md-2 mt-4">
                                                                                                    <div class="form-group">
                                                                                                        <h6 for="aboxnum" class="mb-0">[ 배치박스번호 ]</h6>
                                                                                                        <input id="aboxnum" type="number" class="form-control" placeholder="배치박스번호" v-model="stocklist.출고박스번호">
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div class="col-md-12 mt-4">
                                                                                                    <div class="custom-control custom-switch custom-control-inline">
                                                                                                        <input type="checkbox" class="custom-control-input" id="customSwitch2" v-model="rearrange">
                                                                                                        <label class="custom-control-label" for="customSwitch2">저장 후 전체박스 재 정렬</label>
                                                                                                    </div>          
                                                                                                </div>
                                                                                                                                        
                                                                                            </div>
                                                                                        </b-modal>



                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </tab-content-item>
                                                </tab-content>

                                               
                                                
                                            </b-modal>




                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div> 
                                            </div>
                                            
                                            

                                            
                                        </div>
                                                                                                                                                                                                                                                                                   
                                    </b-modal>
            </div>



            </div>









            
        </div>
        <!-- Page end  -->
    </div>
</template>
<script>
import * as XLSX from 'xlsx';
import axios from 'axios';
export default {
    name:'listproduct',
  
    data(){
        return{
            reqlists:[],

            picked1: "radio1",
            showtable: true,
            showdetailtable: false,
            stdlength: 0,
            stdwidth: 0,
            stdheight: 0,

            milkrundetail: [],
            확정수량: '',
            박스인입수량 : '',
            배치박스번호: '',
            rearrange: true,


            title:"발주리스트",
            desc1:"발주서가 많은 경우 불러오는 데 다소 시간이 걸릴 수 있습니다.",
            desc2:"Chrome 웹스토어에서 COUPLUS Extension을 다운받은 후 발주서내역을 수집해주세요",
            link:{ name: 'product.addproduct'},
            linktext:"Add Product",
            selected: '',
            selectpo: '발주내역',
            selectoption: '발주일',
            picked: 'radio1',
            // checksstock: false,
            showspinner: false,

            status:'전체',

            selectdate: 'today',
            startdate: this.getTodaywith(),
            enddate: this.getTodaywith(),

            selection: "1",
            showlist:false,
            발주번호: '',
            SKUID: '',
            바코드: '',

            mototal: 0,
            moconfirm: 0,
            mostock: 0,
            moaddstock: 0,
            moastock: 0,
            mopstock: 0,
            morstock: 0,
            mosstock: 0,
            minusprofit: 0,
            mocancel: 0,

            confirmpo: 'all',

            menuint: ["발주상태","발주일시","발주운송","첫SKU명","발주수량","발주센터","입고예정일","금액"],
            menuDefault: ["발주상태","발주일시","발주운송","첫SKU명","발주수량","발주센터","입고예정일","금액"],
            menuDetail: ["발주상태","발주일시","발주운송","첫SKU명","발주수량","발주센터","입고예정일","금액","입고수량","입고시작일","입고종료일","예약상태"],
            //menuSales: ["바코드","구매링크","판매가격","판매링크","판매재고","판매상품명"],
            menupodetail: ["바코드","발주수량","확정수량","배치수량","구매필요수량","미배치재고수량","구매완료수량","회송예정수량","사입재고수량","매입가","공급가"],
            menureq:["발주수량","확정수량","사입재고수량","국내재고","해외재고","구매필요수량"],
            menureqth:["발주","확정","사입","국내","해외","필요"],
            polists: [],
            searchresult: [],

            boxdetail: [],
            boxdetailinit: [],

            boxlists: [],
            boxlistsinit: [],

            checkbox1:false,

            showboxdetail:false,
            totalpo: 0,
            totalcancel : 0,

            reasons: [
                '제조사 생산중단 혹은 공급사 취급중단 - 제품 리뉴얼/모델 변경',
                '제조사 생산중단 혹은 공급사 취급중단 - 시장 단종',
                '제조사 생산중단 혹은 공급사 취급중단 - 사업자변경',
                '협력사 재고부족 - 수요예측 오류',
                '협력사 재고부족 - 생산캐파 부족 (설비라인/원자재/인력/휴무… 등등)',
                '협력사 재고부족 - 품질적 이슈 (유해물질 발견 / 유통기한 미달)',
                '협력사 재고부족 - 재고 할당정책',
                '협력사 재고부족 - 수입상품 입고지연 (선적/통관지연)',
                'FC 입고기준 미달로 회송',
                '가격 이슈 (Price) - 매입가 인하 협상 중',
                '가격 이슈 (Price) - 매입가 인상 협상 중',
                '가격 이슈 (Price) - 쿠팡 최저가 매칭',
                '최소발주량 변경 필요 (MOQ)',
                '쿠팡 요청 미납',
                '시즌상품으로 다음 시즌전까지 생산 혹은 취급중단',
            ],
            reason: '협력사 재고부족 - 수요예측 오류',
            logistics: '쉽먼트'
            //진행현황 : 
            //--> 주문전, 주문후중국택배이동, 배대지입고, 배대지작업중, (배대지작업중오류), 출고

        }
    },
    mounted() {
        this.stdlength = parseInt(this.$store.state.settings[0].CreatePo_Boxsize_가로);
        this.stdwidth = parseInt(this.$store.state.settings[0].CreatePo_Boxsize_세로);
        this.stdheight = parseInt(this.$store.state.settings[0].CreatePo_Boxsize_높이);
    },
    methods: {
        deletereqitem(i){
            this.reqlists = this.reqlists.slice(0,i).concat(this.reqlists.slice(i+1))
        },
        openreqpurchase(){
            this.reqlists = [];
            this.polists.forEach(e => {
                if(!e.발주상태.includes('거래명세서') && !e.발주상태.includes('발주취소')){
                    e.발주세부내역.forEach(ele => {
                        var cloneobj = this.deepClone(ele);
                        cloneobj.발주번호 = e.발주번호;
                        cloneobj.발주센터 = e.발주센터;
                        cloneobj.입고예정일 = e.입고예정일;
                        cloneobj.구매요청수량 = cloneobj.구매필요수량;
                        this.reqlists.push(this.deepClone(cloneobj))
                    })
                }
            })
            
            this.$bvModal.show('reqpurchase')
        },
        changeboxvolume(){
            console.log(this.stdlength + 'x' + this.stdwidth + 'x' + this.stdheight)
        },
        modalmulti2(index){
            return 'modal-multi-2' + index
        },
        reqpurchase(){
            //구매대행요청




        },
        savechangeinfo(){
            this.boxdetail.filter(e => e.checked).forEach(e => {
                e.발주운송 = this.logistics;
                if(this.확정수량){
                    e.확정수량 = this.확정수량;
                }
                if(this.박스인입수량){
                    e['1박스인입수량'] = this.박스인입수량;
                }
                if(this.배치박스번호){
                    e.출고박스번호 = this.배치박스번호;
                }
            })            
            if(this.rearrange){
                this.sortbox()
            }
            this.확정수량 = '';
            this.박스인입수량 = '';
            this.배치박스번호 = '';
        },
        changeshipment(){
            if(this.boxdetail.filter(e => e.checked).length == 0){
                alert('변경할 내역을 선택해 주세요')
                return
            }
            this.boxdetail.filter(e => e.checked).forEach(e => {
                if(e.발주운송 == '쉽먼트'){
                    e.발주운송 = '밀크런'
                } else {
                    e.발주운송 = '쉽먼트'
                }
            })
        },
        changeinfo(boxdetail){
            if(boxdetail.filter(e => e.checked).length == 0){
                alert('변경할 내역을 선택해 주세요')
                return
            }
            this.$bvModal.show('modal-multi-3')
        },
        deleteitem(){
            if(this.boxdetail.filter(e => e.checked).length == 0){
                alert('삭제할 내역을 선택해 주세요')
                return
            }
            if(confirm(this.boxdetail.filter(e => e.checked).length + '개의 내역을 삭제 하시겠습니까?')){
                var arr = [];
                this.boxdetail.filter(e => !e.checked).forEach(e => {
                    arr.push(this.deepClone(e))
                })
                this.boxdetail = arr;
            }
        },
        reasonchange(reason){
            this.reason = reason;
        },
        async requestpurchase($event){
            $event.preventDefault();
            var 요청건수 = this.reqlists.filter(e => parseInt(e.구매요청수량) > 0).length;
            var 요청수량 = this.reqlists.reduce((pv,cv) => {return pv + parseInt(cv.구매요청수량)},0);
            this.$bvModal.show('waitreqpurchase');
            if(confirm(요청건수 + '건(총수량:' + 요청수량 + '개)의 내역을 구매요청하시겠습니까?')){
                var arr = [];
                for(var i=0;i<this.reqlists.length;i++){
                    var e = this.reqlists[i];
                    var obj = await this.addlists(e);
                    arr.push(this.deepClone(obj));
                }
                await this.addreqlist(arr)
            }
        },
        async addreqlist(lists){
            console.log(lists)
            try{
                var res = await axios.post('/api/reqpurchase/addreqlist',lists)
                console.log(res)
            }catch(e){
                console.log(e)
            }
            this.$bvModal.hide('waitreqpurchase');
            if(confirm('저장이 완료되었습니다. 구매요청메뉴로 이동하시겠습니까?')){
                this.$router.push({name:'purchase.reqpurchase'})
            }
            this.$bvModal.hide('reqpurchase');
        },
        async addlists(e){
            var ob = this.$store.state.productdb.find(ele => parseInt(ele['SKU ID']) == parseInt(e.SKUID))
            var 발주 = this.polists.filter(ele => ele.발주번호 == e.발주번호);
            var poarr = [];
            if(발주.length > 0){
                var cloneobj = {};
                발주.forEach(e => {
                    if(!e.발주상태.includes('거래명세서') && !e.발주상태.includes('발주취소')){
                        e.발주세부내역.forEach(ele => {
                            if(ele.바코드 == ob.바코드){
                                cloneobj = this.deepClone(e);
                                cloneobj.sku수량 = parseInt(ele.확정수량);
                                poarr.push(this.deepClone(cloneobj))
                            }
                        })
                    }
                })
            }
            var obj = {
                checked : false,
                showspinner : false,
                SKUID : ob['SKU ID'],
                바코드 : ob.바코드 ? ob.바코드 : '',
                상품명 : ob.상품명,
                상품명init : '',
                진행현황 : '구매전',
                구매링크 : ob.구매링크 ? ob.구매링크 : undefined,
                구매링크init : ob.구매링크 ? ob.구매링크 : undefined,
                옵션1_중국어 : ob.옵션1_중국어 ? ob.옵션1_중국어 : undefined,
                옵션2_중국어 : ob.옵션2_중국어 ? ob.옵션2_중국어 : undefined,
                옵션이미지: ob.옵션이미지 ? ob.옵션이미지 : undefined,
                매칭상태 : ob.매칭상태 ? ob.매칭상태 : '매칭필요',
                상품매칭 : {
                    옵션1_중국어 : [],
                    옵션2_중국어 : [],
                },
                구매재고 : '',
                구매요청수량 : e.구매요청수량,
                판매구성수량: ob.판매구성수량 ? (ob.판매구성수량 == '단품' ? 1 : ob.판매구성수량) : 1,
                묶음구성여부 : ob.묶음구성여부 ? ob.묶음구성여부 : false,
                묶음구성수량 : ob.묶음구성수량 ? ob.묶음구성수량 : '',
                묶음대상바코드 : ob.묶음대상바코드 ? ob.묶음대상바코드 : '',
                신고영문명 : ob.신고영문명 ? ob.신고영문명 : '',
                신고가격 : ob['신고가격(USD)'] ? ob['신고가격(USD)'] : '',
                HSCODE : ob['HS-CODE'] ? ob['HS-CODE'] : '',
                재고동봉 : true,
                등록이미지 : ob.등록이미지,
                요청사항 : '요청등록',
                reqtype : '',
                sizeX : ob.sizeX ? ob.sizeX : '',
                sizeY : ob.sizeY ? ob.sizeY : '',
                sizeZ : ob.sizeZ ? ob.sizeZ : '',
                weight : ob.weight ? ob.weight : '',
                요청내역 : {
                    default : true,
                    poarrange : true,

                    boxnumber : '',
                    한글표시사항 : true,
                    barcode : true,
                    sticker : false,
                    stamp : false,
                    tag : false,
                    reqcontent : '',
                    opp : false,
                    LDPE : false,
                    airpacking : false,
                    exportType : 'pallet',
                },
                한글표시사항 : {
                    제품명 : ob.상품명,
                    '수입원/판매원' : ob['수입원/판매원'],
                    제조원 : ob.제조원,
                    제조국 : ob.제조국,
                    내용량 : ob.내용량,
                    '원료명및성분명(재질)' : ob['원료명및성분명(재질)'],
                    상품유형 : ob.상품유형,
                    사용시주의사항 : ob.사용시주의사항,
                    사용기준 : ob.사용기준,
                },
                발주수량 : poarr[0].sku수량,
                polists : poarr,
                selectpo : e.발주번호,
            }
            var newObj = await this.confirmreq(obj);
            return newObj
        },
        async confirmreq(obj){
            if(!obj.구매링크 || obj.구매링크 == '사입'){
                obj.showspinner = false;
                return obj
            }
            obj.구매링크init = obj.구매링크;
            obj.매칭상태 = '매칭필요'
            var item = await this.getProductInfo(obj.구매링크);
            console.log(item)
            obj.item = item;

            if(item.format_check == 'fail'){
                obj.상품매칭.옵션1_중국어 = [];
                obj.상품매칭.옵션2_중국어 = [];
                obj.옵션1_중국어 = '';
                obj.옵션2_중국어 = '';
                obj.구매재고 = '구매불가';
                obj.등록이미지 = obj.등록이미지 ? obj.등록이미지 : item.pic_url;
                obj.옵션이미지 = obj.옵션이미지 ? obj.옵션이미지 : item.pic_url;
                obj.매칭상태 = '매칭필요';
                obj.showspinner = false;
                return obj
            }

            var option1array = [];
            var option2array = [];
            if(item.props_list && Object.keys(item.props_list).length > 0){
                Object.keys(item.props_list).forEach(e => {
                    if(e[0] == '0'){
                        option1array.push({
                            properties : e,
                            name : item.props_list[e].split(":")[1],
                            imageUrl : item.props_img[e] ? item.props_img[e] : '',
                        })
                    }
                    if(e[0] == '1'){
                        option2array.push({
                            properties : e,
                            name : item.props_list[e].split(":")[1],
                            imageUrl : item.props_img[e] ? item.props_img[e] : '',
                        })
                    }
                })
            }

            if(option1array.length == 0){
                obj.상품매칭.옵션1_중국어 = [];
                obj.상품매칭.옵션2_중국어 = [];
                obj.옵션1_중국어 = '';
                obj.옵션2_중국어 = '';
                obj.구매재고 = parseInt(item.num);
                obj.등록이미지 = obj.등록이미지 ? obj.등록이미지 : item.pic_url;
                obj.옵션이미지 = obj.옵션이미지 ? obj.옵션이미지 : item.pic_url;
                obj.매칭상태 = '매칭완료'
            } else {
                if(option2array.length == 0){
                    var imgUrl = '';
                    if(!option1array.find(e => e.name == obj.옵션1_중국어)){
                        obj.옵션1_중국어 = undefined;
                        obj.옵션이미지 = undefined;
                        obj.구매재고 = 0;
                    } else {
                        var option1properties = option1array.find(e => e.name == obj.옵션1_중국어).properties;
                        obj.구매재고 = parseInt(item.skus.sku.find(e => e.properties == option1properties).quantity);
                        if(item.prop_imgs.prop_img.length > 0){
                            imgUrl = item.prop_imgs.prop_img.find(e => e.properties == option1properties).url;
                        }
                        obj.옵션이미지 = imgUrl;
                        obj.매칭상태 = '매칭완료'
                    }
                    obj.상품매칭.옵션1_중국어 = option1array;
                    obj.상품매칭.옵션2_중국어 = [];
                    obj.옵션2_중국어 = '';
                    obj.등록이미지 = obj.등록이미지 ? obj.등록이미지 : imgUrl;
                } else {
                    imgUrl = '';
                    if(!option1array.find(e => e.name == obj.옵션1_중국어)){
                        obj.옵션1_중국어 = undefined;
                        obj.옵션이미지 = undefined;
                        obj.구매재고 = 0;
                    } else {
                        option1properties = option1array.find(e => e.name == obj.옵션1_중국어).properties;
                        if(item.prop_imgs.prop_img && item.prop_imgs.prop_img.find(e => e.properties == option1properties)){
                            imgUrl = item.prop_imgs.prop_img.find(e => e.properties == option1properties).url;
                        }
                        obj.옵션이미지 = imgUrl;
                        if(!option2array.find(e => e.name == obj.옵션2_중국어)){
                            obj.옵션2_중국어 = undefined;
                            obj.구매재고 = 0;
                        } else {
                            var option2properties = option2array.find(e => e.name == obj.옵션2_중국어).properties;
                            obj.구매재고 = parseInt(item.skus.sku.find(e => e.properties == (option1properties + ';' + option2properties)).quantity);
                            if(item.prop_imgs.prop_img && item.prop_imgs.prop_img.find(e => e.properties == option2properties)){
                                imgUrl = item.prop_imgs.prop_img.find(e => e.properties == option2properties).url;
                            }
                            obj.옵션이미지 = imgUrl;
                            obj.매칭상태 = '매칭완료'
                        }
                    }
                    obj.상품매칭.옵션1_중국어 = option1array;
                    obj.상품매칭.옵션2_중국어 = option2array;
                    obj.등록이미지 = obj.등록이미지 ? obj.등록이미지 : imgUrl;
                }
            }
            if(!obj.등록이미지 && !option1array.find(e => e.imageUrl) && !option1array.find(e => e.imageUrl)){
                obj.등록이미지 = item.pic_url;
            }
            obj.showspinner = false;
            return obj
        },
        async getProductInfo(구매링크){
            var productNo = 구매링크.slice(구매링크.search('offer/')+6,구매링크.search('.html'));
            console.log(productNo)
            try{
                var res = await axios.post('/api/reqpurchase/getProductInfo',[productNo])    
                return res.data.result[0].item
            }catch(e){
                console.log('err821' + e); 
                return false
            }
        },
        async changeboxqty(stocklist){
            var arr = [];
            arr.push(stocklist)
            axios.post('/api/listcategory/updateboxqty',arr)
			.then((res) => {
				if(res.data.요청결과 == "수정완료"){
                    this.$store.commit('updateboxqty',stocklist)
				} else {
                    alert('업데이트 실패(469)');
                    console.log(res.data)
                }
			})	
			.catch((e) => {alert("업데이트 실패(473)");console.log(e);})
        },
        clickdb(stocklist){
            stocklist.showchangedb = true;
        },
        downloadstocklists(){
            var arr = [];
            this.boxdetail.forEach(e => {
                arr.push(this.deepClone(e))
            })
            arr.forEach(e => {
                delete e.checked;
                delete e.showchangedb;
            })
            const workBook = XLSX.utils.book_new()
            const workSheet1 = XLSX.utils.json_to_sheet(arr)
            XLSX.utils.book_append_sheet(workBook, workSheet1, '재고세부내역')
            XLSX.writeFile(workBook, '재고세부내역.xlsx')
        },
        checkallbox(list,a){
            if(a == 'shipment'){
                if(list.shipmentcheck){
                    list.shipmentdetail.forEach(e => {
                        e.checked = true;
                    })
                } else {
                    list.shipmentdetail.forEach(e => {
                        e.checked = false;
                    })
                }
            } else {
                if(list.milkruncheck){
                    list.milkrundetail.forEach(e => {
                        e.checked = true;
                    })
                } else {
                    list.milkrundetail.forEach(e => {
                        e.checked = false;
                    })
                }                
            }
        },
        arrangelist(){
            this.totaldatesinit()
        },
        totaldatesinit2(){
            var arr = [];
            var obj = {}; // 입고예정일 / 발주센터 / 쉽먼트_확정전 / 쉽먼트_확정후 / 밀크런_확정전 / 밀크런_확정후 / boxdetail / milkrundetail
            this.boxlists = [];
            this.boxlistsinit = [];

            this.polists.forEach(e => {
                if(e.발주상태.includes('거래처확인요청') || e.발주상태.includes('발주확정')){
                    obj = {
                        입고예정일 : e.입고예정일,
                        발주센터 : e.발주센터,
                        발주번호 : e.발주번호,
                        발주세부내역 : e.발주세부내역,
                        발주상태 : e.발주상태,
                        발주운송 : e.발주운송
                    }
                    arr.push(obj)
                }
            })

            var stocks = [];
            var so = {}
            var stockfilter = this.$store.state.stock.filter(e => e.stockinfo == 'stock')
            stockfilter.forEach(e => {
                if(e.site == 'kr'){
                    if(stocks.length > 0 && stocks.filter(ele => ele.바코드 == e.바코드).length > 0){
                        stocks.find(ele => ele.바코드 == e.바코드).수량++
                    } else {
                        so = {
                            site : 'kr',
                            바코드 : e.바코드,
                            수량 : 1
                        }
                        stocks.push(so)
                    }
                } else {
                    if(stocks.length > 0 && stocks.filter(ele => ele.바코드 == e.바코드).length > 0){
                        stocks.find(ele => ele.바코드 == e.바코드).수량++
                    } else {
                        so = {
                            site : 'cn',
                            바코드 : e.바코드,
                            수량 : 1
                        }
                        stocks.push(so)
                    }
                }
            })

            var 주문수량 = 0;
            if(this.$store.state.purchase.length > 0){
                this.$store.state.purchase.filter(e => e.배송상태 != '입고완료').forEach(e => {
                    if(stocks.length > 0 && stocks.filter(ele => ele.site == 'po' && ele.바코드 == e.바코드).length > 0){
                        if(this.$store.state.productdb.find(ele => ele.바코드 == e.바코드).판매구성수량){
                            if(this.$store.state.productdb.find(ele => ele.바코드 == e.바코드).판매구성수량 == '단품' || parseInt(this.$store.state.productdb.find(ele => ele.바코드 == e.바코드).판매구성수량) == 1){
                                stocks.find(ele => ele.site == 'po' && ele.바코드 == e.바코드).수량 += e.주문수량;
                            } else {
                                stocks.find(ele => ele.site == 'po' && ele.바코드 == e.바코드).수량 += Math.floor(e.주문수량/parseInt(this.$store.state.productdb.find(ele => ele.바코드 == e.바코드).판매구성수량));
                            }
                        } else {
                            stocks.find(ele => ele.site == 'po' && ele.바코드 == e.바코드).수량 += e.주문수량;
                        }
                    } else {
                        if(this.$store.state.productdb.find(ele => ele.바코드 == e.바코드).판매구성수량){
                            if(this.$store.state.productdb.find(ele => ele.바코드 == e.바코드).판매구성수량 == '단품' || parseInt(this.$store.state.productdb.find(ele => ele.바코드 == e.바코드).판매구성수량) == 1){
                                주문수량 = e.주문수량;
                            } else {
                                주문수량 = Math.floor(e.주문수량/parseInt(this.$store.state.productdb.find(ele => ele.바코드 == e.바코드).판매구성수량));
                            }
                        } else {
                            주문수량 = e.주문수량;
                        }
                        so = {
                            site : 'po',
                            바코드 : e.바코드,
                            수량 : 주문수량
                        }
                        stocks.push(so)
                    }
                })
            }

            
            arr.sort(function(a,b){
                if (a.발주번호 < b.발주번호){
                    return -1;
                }
                if (a.발주번호 > b.발주번호){
                    return 1;
                }
                return 0;
            })
            arr.sort(function(a,b){
                if (a.발주센터 < b.발주센터){
                    return -1;
                }
                if (a.발주센터 > b.발주센터){
                    return 1;
                }
                return 0;
            })
            arr.sort(function(a,b){
                return new Date(a.입고예정일) - new Date(b.입고예정일);
            })
            

            


        },
        totaldatesinit(){
            var arr = [];
            var obj = {}; // 입고예정일 / 발주센터 / 총박스수량 / 국내재고박스수량 / 물류재고박스수량 / 구매박스수량 / 회송박스수량
            this.boxlists = [];
            this.boxlistsinit = [];
            //polist중 발주확정 안된 것들만 sorting
            this.polists.forEach(e => {
                if(e.발주상태.includes('거래처확인요청') || e.발주상태.includes('발주확정')){
                    obj = {
                        입고예정일 : e.입고예정일,
                        발주센터 : e.발주센터,
                        발주번호 : e.발주번호,
                        발주세부내역 : e.발주세부내역,
                        발주상태 : e.발주상태,
                        발주운송 : e.발주운송
                    }
                    arr.push(obj)
                }
            })
            var vol;

            var stocks = [];
            var so = {}
            var stockfilter = this.$store.state.stock.filter(e => e.stockinfo == 'stock')
            stockfilter.forEach(e => {
                if(e.site == 'kr'){
                    if(stocks.length > 0 && stocks.filter(ele => ele.바코드 == e.바코드).length > 0){
                        stocks.find(ele => ele.바코드 == e.바코드).수량++
                    } else {
                        so = {
                            site : 'kr',
                            바코드 : e.바코드,
                            수량 : 1
                        }
                        stocks.push(so)
                    }
                } else {
                    if(stocks.length > 0 && stocks.filter(ele => ele.바코드 == e.바코드).length > 0){
                        stocks.find(ele => ele.바코드 == e.바코드).수량++
                    } else {
                        so = {
                            site : 'cn',
                            바코드 : e.바코드,
                            수량 : 1
                        }
                        stocks.push(so)
                    }
                }
            })

            var 주문수량 = 0;
            if(this.$store.state.purchase.length > 0){
                this.$store.state.purchase.filter(e => e.배송상태 != '입고완료').forEach(e => {
                    if(stocks.length > 0 && stocks.filter(ele => ele.site == 'po' && ele.바코드 == e.바코드).length > 0){
                        if(this.$store.state.productdb.find(ele => ele.바코드 == e.바코드)?.판매구성수량){
                            if(this.$store.state.productdb.find(ele => ele.바코드 == e.바코드).판매구성수량 == '단품' || parseInt(this.$store.state.productdb.find(ele => ele.바코드 == e.바코드).판매구성수량) == 1){
                                stocks.find(ele => ele.site == 'po' && ele.바코드 == e.바코드).수량 += e.주문수량;
                            } else {
                                stocks.find(ele => ele.site == 'po' && ele.바코드 == e.바코드).수량 += Math.floor(e.주문수량/parseInt(this.$store.state.productdb.find(ele => ele.바코드 == e.바코드).판매구성수량));
                            }
                        } else {
                            stocks.find(ele => ele.site == 'po' && ele.바코드 == e.바코드).수량 += e.주문수량;
                        }
                    } else {
                        if(this.$store.state.productdb.find(ele => ele.바코드 == e.바코드)?.판매구성수량){
                            if(this.$store.state.productdb.find(ele => ele.바코드 == e.바코드).판매구성수량 == '단품' || parseInt(this.$store.state.productdb.find(ele => ele.바코드 == e.바코드).판매구성수량) == 1){
                                주문수량 = e.주문수량;
                            } else {
                                주문수량 = Math.floor(e.주문수량/parseInt(this.$store.state.productdb.find(ele => ele.바코드 == e.바코드).판매구성수량));
                            }
                        } else {
                            주문수량 = e.주문수량;
                        }
                        so = {
                            site : 'po',
                            바코드 : e.바코드,
                            수량 : 주문수량
                        }
                        stocks.push(so)
                    }
                })
            }

            
            arr.sort(function(a,b){
                if (a.발주번호 < b.발주번호){
                    return -1;
                }
                if (a.발주번호 > b.발주번호){
                    return 1;
                }
                return 0;
            })
            arr.sort(function(a,b){
                if (a.발주센터 < b.발주센터){
                    return -1;
                }
                if (a.발주센터 > b.발주센터){
                    return 1;
                }
                return 0;
            })
            arr.sort(function(a,b){
                return new Date(a.입고예정일) - new Date(b.입고예정일);
            })


            // if(!arr.find(e => e.발주세부내역.find(ele => this.$store.state.productdb.find(element => element.바코드 == ele.바코드)['1박스인입수량']))){
            //     alert('상품별 박스수량 확인을 위해 상품 > 상품DB등록 > 다운로드 후 엑셀에서 "1박스인입수량"열을 먼저 업데이트 해주세요. 1박스인입수량이 업데이트되지 않은 상품은 예상박스수량에 포함되지 않습니다.')
            // }

            console.log(stocks) //재고
            console.log(arr) //발주서내역
            var cnt = 0;
            var krstock = 0;
            var pstock = 0;
            var cnstock = 0;
            var totalcnt = 0;

            //발주세부내역에 재고수량/재고박스수량 추가
            arr.forEach(e => {
                krstock = 0;
                cnstock = 0;
                pstock = 0;
                totalcnt = 0;
                // rstock = 0;
                e.발주세부내역.forEach(ele => {
                    cnt = parseInt(ele.확정수량)
                    ele.국내재고수량 = 0;
                    ele.해외재고수량 = 0;
                    ele.구매재고수량 = 0;
                    ele.국내재고박스수량 = 0;
                    ele.해외재고박스수량 = 0;
                    ele.구매재고박스수량 = 0;
                    ele.확정박스수량 = 0;
                    if(this.$store.state.productdb && this.$store.state.productdb.find(element => element.바코드 == ele.바코드)['1박스인입수량']){
                        vol = 1/this.$store.state.productdb.find(element => element.바코드 == ele.바코드)['1박스인입수량']
                        ele['1박스인입수량'] = this.$store.state.productdb.find(element => element.바코드 == ele.바코드)['1박스인입수량'];
                    } else {
                        vol = 0
                        ele['1박스인입수량'] = 0;
                    }
                    if(this.$store.state.productdb && this.$store.state.productdb.find(element => element.바코드 == ele.바코드) && this.$store.state.productdb.find(element => element.바코드 == ele.바코드).구매링크 == '사입'){
                        ele.국내재고수량 = cnt
                        ele.국내재고박스수량 += cnt * vol;
                        krstock += ele.국내재고박스수량;
                        cnt = 0;
                    }
                    if(this.$store.state.stock && this.$store.state.stock.find(element => element.배치발주번호 == e.발주번호 && element.바코드 == ele.바코드)){ ////// -------------------------나중에 삭제
                        ele.국내재고수량 += this.$store.state.stock.filter(element => element.site == 'kr' && element.배치발주번호 == e.발주번호 && element.바코드 == ele.바코드).length
                        if(cnt >= ele.국내재고수량){
                            ele.국내재고박스수량 += ele.국내재고수량 * vol;
                            krstock += ele.국내재고박스수량;
                            cnt -= ele.국내재고수량;
                        } else {
                            ele.국내재고박스수량 += cnt * vol;
                            krstock += ele.국내재고박스수량;
                            cnt = 0;
                        }
                        ele.해외재고수량 += this.$store.state.stock.filter(element => element.site != 'kr' && element.배치발주번호 == e.발주번호 && element.바코드 == ele.바코드).length;
                        if(cnt >= ele.해외재고수량){
                            ele.해외재고박스수량 += ele.해외재고수량 * vol;
                            cnstock += ele.해외재고박스수량;
                            cnt -= ele.해외재고수량;
                        } else {
                            ele.해외재고박스수량 += cnt * vol;
                            cnstock += ele.해외재고박스수량;
                            cnt = 0;
                        }
                    }
                    if(stocks.find(element => element.site == 'kr' && element.바코드 == ele.바코드) && cnt > 0){
                        ele.국내재고수량 += stocks.find(element => element.site == 'kr' && element.바코드 == ele.바코드).수량
                        if(cnt >= ele.국내재고수량){
                            ele.국내재고박스수량 += ele.국내재고수량 * vol;
                            krstock += ele.국내재고박스수량;
                            cnt -= ele.국내재고수량;
                        } else {
                            ele.국내재고박스수량 += cnt * vol;
                            krstock += ele.국내재고박스수량;
                            cnt = 0;
                        }
                    }
                    if(stocks.find(element => element.site == 'cn' && element.바코드 == ele.바코드) && cnt > 0){
                        ele.해외재고수량 += stocks.find(element => element.site == 'cn' && element.바코드 == ele.바코드).수량;
                        if(cnt >= ele.해외재고수량){
                            ele.해외재고박스수량 += ele.해외재고수량 * vol;
                            cnstock += ele.해외재고박스수량;
                            cnt -= ele.해외재고수량;
                        } else {
                            ele.해외재고박스수량 += cnt * vol;
                            cnstock += ele.해외재고박스수량;
                            cnt = 0;
                        }
                    }
                    // rstock += stocks.find(element => element.site == 'kr' && element.바코드 == ele.바코드).수량 * vol;
                    if(stocks.find(element => element.site == 'po' && element.바코드 == ele.바코드) && cnt > 0){
                        ele.구매재고수량 = stocks.find(element => element.site == 'po' && element.바코드 == ele.바코드).수량;
                        if(cnt >= ele.구매재고수량){
                            ele.구매재고박스수량 += ele.구매재고수량 * vol
                            pstock += ele.구매재고박스수량;
                            cnt -= ele.구매재고수량;
                        } else {
                            ele.구매재고박스수량 += cnt * vol;
                            pstock += ele.구매재고박스수량;
                            cnt = 0;
                        }
                    }
                    ele.확정박스수량 += parseInt(ele.확정수량) * vol
                    totalcnt += ele.확정박스수량;

                    stocks = stocks.filter(element => element.바코드 != ele.바코드);
                    ele.등록이미지 = this.$store.state.productdb.find(element => element.바코드 == ele.바코드).등록이미지;

                    if(ele.확정수량 == 0){
                        ele.부피대비수익률 = 0
                    } else {
                        ele.부피대비수익률 = (parseInt(ele.매입가.replace(/,/g, ''))) / vol
                    }
                    ele.부족재고 = cnt;
                    
                })
                e.국내재고박스수량 = krstock;
                e.해외재고박스수량 = cnstock;
                e.구매재고박스수량 = pstock;

                e.총박스수량 = krstock + cnstock + pstock;
                e.총확정박스수량 = totalcnt;
                // e.회송재고박스수량 = rstock;
                
            })
            // console.log(arr)

            //발주내역에서 출고박스리스트 추출
            var oj = {}
            var ojadd = {}
            // var ta
            // var result = [];
            arr.forEach(e => {
                if(this.boxlistsinit.length > 0 && this.boxlistsinit.filter(ele => ele.발주센터 == e.발주센터 && ele.입고예정일 == e.입고예정일).length > 0){
                    ojadd = {
                        발주번호 : e.발주번호,
                        발주세부내역 : e.발주세부내역,
                        발주상태 : e.발주상태,
                        발주운송 : e.발주운송
                    }
                    this.boxlistsinit.find(ele => ele.발주센터 == e.발주센터 && ele.입고예정일 == e.입고예정일).발주내역.push(ojadd)
                    this.boxlistsinit.find(ele => ele.발주센터 == e.발주센터 && ele.입고예정일 == e.입고예정일).국내재고박스수량 += e.국내재고박스수량;
                    this.boxlistsinit.find(ele => ele.발주센터 == e.발주센터 && ele.입고예정일 == e.입고예정일).해외재고박스수량 += e.해외재고박스수량;
                    this.boxlistsinit.find(ele => ele.발주센터 == e.발주센터 && ele.입고예정일 == e.입고예정일).구매재고박스수량 += e.구매재고박스수량;
                    this.boxlistsinit.find(ele => ele.발주센터 == e.발주센터 && ele.입고예정일 == e.입고예정일).총박스수량 = this.boxlistsinit.find(ele => ele.발주센터 == e.발주센터 && ele.입고예정일 == e.입고예정일).국내재고박스수량 + this.boxlistsinit.find(ele => ele.발주센터 == e.발주센터 && ele.입고예정일 == e.입고예정일).해외재고박스수량 + this.boxlistsinit.find(ele => ele.발주센터 == e.발주센터 && ele.입고예정일 == e.입고예정일).구매재고박스수량
                    this.boxlistsinit.find(ele => ele.발주센터 == e.발주센터 && ele.입고예정일 == e.입고예정일).총확정박스수량 += e.총확정박스수량;

                    if(e.발주상태.includes('거래처')){
                        this.boxlistsinit.find(ele => ele.발주센터 == e.발주센터 && ele.입고예정일 == e.입고예정일).국내재고박스수량_확정전 += e.국내재고박스수량;
                        this.boxlistsinit.find(ele => ele.발주센터 == e.발주센터 && ele.입고예정일 == e.입고예정일).해외재고박스수량_확정전 += e.해외재고박스수량;
                        this.boxlistsinit.find(ele => ele.발주센터 == e.발주센터 && ele.입고예정일 == e.입고예정일).구매재고박스수량_확정전 += e.구매재고박스수량;
                        this.boxlistsinit.find(ele => ele.발주센터 == e.발주센터 && ele.입고예정일 == e.입고예정일).총박스수량_확정전 = this.boxlistsinit.find(ele => ele.발주센터 == e.발주센터 && ele.입고예정일 == e.입고예정일).국내재고박스수량_확정전 + this.boxlistsinit.find(ele => ele.발주센터 == e.발주센터 && ele.입고예정일 == e.입고예정일).해외재고박스수량_확정전 + this.boxlistsinit.find(ele => ele.발주센터 == e.발주센터 && ele.입고예정일 == e.입고예정일).구매재고박스수량_확정전
                    } else {
                        this.boxlistsinit.find(ele => ele.발주센터 == e.발주센터 && ele.입고예정일 == e.입고예정일).국내재고박스수량_확정후 += e.국내재고박스수량;
                        this.boxlistsinit.find(ele => ele.발주센터 == e.발주센터 && ele.입고예정일 == e.입고예정일).해외재고박스수량_확정후 += e.해외재고박스수량;
                        this.boxlistsinit.find(ele => ele.발주센터 == e.발주센터 && ele.입고예정일 == e.입고예정일).구매재고박스수량_확정후 += e.구매재고박스수량;
                        this.boxlistsinit.find(ele => ele.발주센터 == e.발주센터 && ele.입고예정일 == e.입고예정일).총박스수량_확정후 = this.boxlistsinit.find(ele => ele.발주센터 == e.발주센터 && ele.입고예정일 == e.입고예정일).국내재고박스수량_확정후 + this.boxlistsinit.find(ele => ele.발주센터 == e.발주센터 && ele.입고예정일 == e.입고예정일).해외재고박스수량_확정후 + this.boxlistsinit.find(ele => ele.발주센터 == e.발주센터 && ele.입고예정일 == e.입고예정일).구매재고박스수량_확정후
                    }

                } else {
                    oj = {
                        국내재고박스수량 : e.국내재고박스수량,
                        해외재고박스수량 : e.해외재고박스수량,
                        구매재고박스수량 : e.구매재고박스수량,
                        총확정박스수량 : e.총확정박스수량,
                        국내재고박스수량_확정전 : e.발주상태.includes('거래처') ? e.국내재고박스수량 : 0,
                        해외재고박스수량_확정전 : e.발주상태.includes('거래처') ? e.해외재고박스수량 : 0,
                        구매재고박스수량_확정전 : e.발주상태.includes('거래처') ? e.구매재고박스수량 : 0,
                        국내재고박스수량_확정후 : e.발주상태.includes('발주확정') ? e.국내재고박스수량 : 0,
                        해외재고박스수량_확정후 : e.발주상태.includes('발주확정') ? e.해외재고박스수량 : 0,
                        구매재고박스수량_확정후 : e.발주상태.includes('발주확정') ? e.구매재고박스수량 : 0,                                          
                        발주내역 : [{
                            발주번호 : e.발주번호,
                            발주세부내역 : e.발주세부내역,
                            발주상태 : e.발주상태,
                            발주운송 : e.발주운송,
                            reason: '협력사 재고부족 - 수요예측 오류',
                            logistics: '쉽먼트'                            
                        }],
                        총박스수량 : e.총박스수량,
                        총박스수량_확정전 : e.발주상태.includes('거래처') ? e.총박스수량 : 0,
                        총박스수량_확정후 : e.발주상태.includes('발주확정') ? e.총박스수량 : 0,
                        발주센터 : e.발주센터,
                        입고예정일 : e.입고예정일,
                        confirmpo : 'all',
                    }

                    // if(e.총박스수량 > 0){
                        this.boxlistsinit.push(oj)
                    // }
                }
            })
            this.boxlistsinit.forEach(e => {
                oj.boxdetailinit = this.openconfirmpo(e);
                oj.boxdetail = JSON.parse(JSON.stringify(oj.boxdetailinit)).filter(e => e.발주운송 === '쉽먼트');
                oj.milkrundetail = JSON.parse(JSON.stringify(oj.boxdetailinit)).filter(e => e.발주운송 !== '쉽먼트');
                this.boxlists.push(this.deepClone(e))
            })
            setTimeout(() => {
                this.$bvModal.show('confirmmodal')
            },50)
        },
        openconfirmpo(list){
            var obj = {}
            var arr = [];
            var boxdetailinit = [];

            list.발주내역.forEach(e => {
                e.발주세부내역.forEach(ele => {
                    obj = {
                        발주번호 : e.발주번호,
                        발주센터 : list.발주센터,
                        발주상태 : e.발주상태,
                        발주운송 : e.발주운송,
                        입고예정일 : list.입고예정일,
                        상품명 : ele.상품명,
                        SKUID : ele.SKUID,
                        바코드 : ele.바코드,
                        등록이미지 : ele.등록이미지,
                        국내재고수량 : ele.국내재고수량,
                        해외재고수량 : ele.해외재고수량,
                        구매재고수량 : ele.구매재고수량,
                        부피대비수익률 : this.confirmpo == 'all' ? ele.부피대비수익률 : ele.국내재고박스수량 + ele.해외재고박스수량 + ele.구매재고박스수량 == 0 ? 0 : ele.부피대비수익률,
                        '1박스인입수량':ele['1박스인입수량'],
                        박스수량 : this.confirmpo == 'all' ? ele.확정박스수량 : ele.국내재고박스수량 + ele.해외재고박스수량 + ele.구매재고박스수량,
                        확정수량 : parseInt(ele.확정수량),
                        발주수량 : parseInt(ele.발주수량)
                    }
                    // if(obj.부피대비수익률 > 0 && obj.확정수량 > 0){
                        arr.push(obj)
                    // }
                })
            })
            console.log(arr)
            

            arr.sort(function(a,b){
                if (a.부피대비수익률 < b.부피대비수익률){
                    return 1;
                }
                if (a.부피대비수익률 > b.부피대비수익률){
                    return -1;
                }
                return 0;
            })
            
            var copyobj = {};
            var i
            var totalkr
            var totalcn
            var totalpu
            var kr
            var cn
            var pu
            var boxqty
            var totalqty
            arr.forEach(e => {
                if(e.박스수량 > 1){
                    boxqty = e.박스수량;
                    totalqty = e.확정수량;
                    totalkr = e.국내재고수량;
                    totalcn = e.해외재고수량;
                    totalpu = e.구매재고수량;
                    for(i=0;i<e.박스수량;i++){ 
                        if(boxqty > 1){
                            if(totalkr >= e['1박스인입수량']){
                                kr = e['1박스인입수량'];
                                cn = 0;
                                pu = 0;
                            } else {
                                if(totalkr + totalcn >= e['1박스인입수량']){
                                    kr = totalkr;
                                    cn = e['1박스인입수량'] - totalkr;
                                    pu = 0;
                                } else {
                                    if(totalkr + totalcn + totalpu >= e['1박스인입수량']){
                                        kr = totalkr;
                                        cn = totalcn;
                                        pu = e['1박스인입수량'] - totalkr - totalcn;
                                    } else {
                                        kr = totalkr;
                                        cn = totalcn;
                                        pu = totalpu;
                                    }
                                }
                            }
                            copyobj = {
                                checked : false,
                                발주번호 : e.발주번호,
                                발주센터 : e.발주센터,
                                발주상태 : e.발주상태,
                                발주운송 : e.발주운송,
                                입고예정일 : e.입고예정일,
                                상품명 : e.상품명,
                                SKUID : e.SKUID,
                                바코드 : e.바코드,
                                등록이미지 : e.등록이미지,
                                국내재고수량 : kr,
                                해외재고수량 : cn,
                                구매재고수량 : pu,
                                부피대비수익률 : e.부피대비수익률,
                                '1박스인입수량':e['1박스인입수량'],
                                박스수량 : 1,
                                확정수량 : e['1박스인입수량'],
                                발주수량 : e.발주수량,
                                showchangedb : true,
                            }
                            

                            boxdetailinit.push(this.deepClone(copyobj))
                            boxqty--
                            totalqty -= e['1박스인입수량'];
                            totalkr -= kr;
                            totalcn -= cn;
                            totalpu -= pu;
                            
                        } else {
                            if(totalkr >= e['1박스인입수량']){
                                kr = e['1박스인입수량'];
                                cn = 0;
                                pu = 0;
                            } else {
                                if(totalkr + totalcn >= e['1박스인입수량']){
                                    kr = totalkr;
                                    cn = e['1박스인입수량'] - totalkr;
                                    pu = 0;
                                } else {
                                    if(totalkr + totalcn + totalpu >= e['1박스인입수량']){
                                        kr = totalkr;
                                        cn = totalcn;
                                        pu = e['1박스인입수량'] - totalkr - totalcn;
                                    } else {
                                        kr = totalkr;
                                        cn = totalcn;
                                        pu = totalpu;
                                    }
                                }
                            }
                            copyobj = {
                                checked : false,
                                발주번호 : e.발주번호,
                                발주센터 : e.발주센터,
                                발주상태 : e.발주상태,
                                발주운송 : e.발주운송,
                                입고예정일 : e.입고예정일,
                                상품명 : e.상품명,
                                SKUID : e.SKUID,
                                바코드 : e.바코드,
                                등록이미지 : e.등록이미지,
                                국내재고수량 : kr,
                                해외재고수량 : cn,
                                구매재고수량 : pu,
                                부피대비수익률 : e.부피대비수익률,
                                '1박스인입수량':e['1박스인입수량'],
                                박스수량 : boxqty,
                                확정수량 : totalqty,
                                발주수량 : e.발주수량,
                                showchangedb : true,
                            }
                            

                            boxdetailinit.push(this.deepClone(copyobj))
                           
                        }
                    }
                } else {
                    totalkr = e.국내재고수량;
                    totalcn = e.해외재고수량;
                    totalpu = e.구매재고수량;
                    if(totalkr >= e.확정수량){
                        kr = e.확정수량;
                        cn = 0;
                        pu = 0;
                    } else {
                        if(totalkr + totalcn >= e.확정수량){
                            kr = totalkr;
                            cn = e.확정수량 - totalkr;
                            pu = 0;
                        } else {
                            if(totalkr + totalcn + totalpu >= e.확정수량){
                                kr = totalkr;
                                cn = totalcn;
                                pu = e.확정수량 - totalkr - totalcn;
                            } else {
                                kr = totalkr;
                                cn = totalcn;
                                pu = totalpu;
                            }
                        }
                    }
                    copyobj = {
                        checked : false,
                        발주번호 : e.발주번호,
                        발주센터 : e.발주센터,
                        발주상태 : e.발주상태,
                        발주운송 : e.발주운송,
                        입고예정일 : e.입고예정일,
                        상품명 : e.상품명,
                        SKUID : e.SKUID,
                        바코드 : e.바코드,
                        등록이미지 : e.등록이미지,
                        국내재고수량 : kr,
                        해외재고수량 : cn,
                        구매재고수량 : pu,
                        부피대비수익률 : e.부피대비수익률,
                        '1박스인입수량':e['1박스인입수량'],
                        박스수량 : e.박스수량,
                        확정수량 : e.확정수량,
                        발주수량 : e.발주수량,
                        showchangedb : true,
                    }
                    boxdetailinit.push(this.deepClone(copyobj))
                }
            })
            var box = this.sortbox(boxdetailinit,list);
            return box
        },
        sortbox(boxdetail,list){
            var n = 0
            if(list.confirmpo == 'all'){
                boxdetail.sort(function(a,b){
                    if (a.확정수량 < b.확정수량){
                        return 1;
                    }
                    if (a.확정수량 > b.확정수량){
                        return -1;
                    }
                    return 0;
                })
                boxdetail.sort(function(a,b){
                    if (a.부피대비수익률 < b.부피대비수익률){
                        return 1;
                    }
                    if (a.부피대비수익률 > b.부피대비수익률){
                        return -1;
                    }
                    return 0;
                })
                boxdetail.sort(function(a,b){
                    if (a.해외재고수량 == 0 && b.해외재고수량 > 0 || a.국내재고수량 == 0 && b.국내재고수량 > 0 || a.구매재고수량 == 0 && b.구매재고수량 > 0){
                        return 1;
                    }
                    if (a.해외재고수량 > 0 && b.해외재고수량 == 0 || a.국내재고수량 > 0 && b.국내재고수량 == 0 || a.구매재고수량 > 0 && b.구매재고수량 == 0){
                        return -1;
                    }
                    return 0;
                })
                boxdetail.sort(function(a,b){
                    if (a.확정수량 == 0 && b.확정수량 > 0){
                        return 1;
                    }
                    if (a.확정수량 > 0 && b.확정수량 == 0){
                        return -1;
                    }
                    return 0;
                })                

                boxdetail.forEach(e => {
                    n += e.박스수량;
                    e.누적박스수량 = n;
                    e.출고박스번호 = Math.ceil(e.누적박스수량);
                })
            }            
            if(list.confirmpo == 'total'){
                boxdetail.sort(function(a,b){
                    if (a.확정수량 < b.확정수량){
                        return 1;
                    }
                    if (a.확정수량 > b.확정수량){
                        return -1;
                    }
                    return 0;
                })
                boxdetail.sort(function(a,b){
                    if (a.부피대비수익률 < b.부피대비수익률){
                        return 1;
                    }
                    if (a.부피대비수익률 > b.부피대비수익률){
                        return -1;
                    }
                    return 0;
                })
                boxdetail.forEach(e => {
                    n += e.박스수량;
                    e.누적박스수량 = n;
                    e.출고박스번호 = Math.ceil(e.누적박스수량);
                })
            }
            if(list.confirmpo == 'stockkr'){
                boxdetail.sort(function(a,b){
                    if (a.확정수량 < b.확정수량){
                        return 1;
                    }
                    if (a.확정수량 > b.확정수량){
                        return -1;
                    }
                    return 0;
                })
                boxdetail.sort(function(a,b){
                    if (a.부피대비수익률 < b.부피대비수익률){
                        return 1;
                    }
                    if (a.부피대비수익률 > b.부피대비수익률){
                        return -1;
                    }
                    return 0;
                })
                boxdetail.sort(function(a,b){
                    if (a.국내재고수량 == 0 && b.국내재고수량 > 0){
                        return 1;
                    }
                    if (a.국내재고수량 > 0 && b.국내재고수량 == 0){
                        return -1;
                    }
                    return 0;
                })                
                boxdetail.forEach(e => {
                    n += e.박스수량;
                    e.누적박스수량 = n;
                    e.출고박스번호 = Math.ceil(e.누적박스수량);
                })
            }
            if(list.confirmpo == 'stocktotal'){
                boxdetail.sort(function(a,b){
                    if (a.확정수량 < b.확정수량){
                        return 1;
                    }
                    if (a.확정수량 > b.확정수량){
                        return -1;
                    }
                    return 0;
                })
                boxdetail.sort(function(a,b){
                    if (a.부피대비수익률 < b.부피대비수익률){
                        return 1;
                    }
                    if (a.부피대비수익률 > b.부피대비수익률){
                        return -1;
                    }
                    return 0;
                })
                boxdetail.sort(function(a,b){
                    if (a.해외재고수량 == 0 && b.해외재고수량 > 0 || a.국내재고수량 == 0 && b.국내재고수량 > 0){
                        return 1;
                    }
                    if (a.해외재고수량 > 0 && b.해외재고수량 == 0 || a.국내재고수량 > 0 && b.국내재고수량 == 0){
                        return -1;
                    }
                    return 0;
                })             
                boxdetail.forEach(e => {
                    n += e.박스수량;
                    e.누적박스수량 = n;
                    e.출고박스번호 = Math.ceil(e.누적박스수량);
                })
            }   
            boxdetail.sort(function(a,b){
                if (a.발주운송 == "쉽먼트" && b.발주운송 != '쉽먼트'){
                    return 1;
                }
                if (a.발주운송 != "쉽먼트" && b.발주운송 == '쉽먼트'){
                    return -1;
                }
                return 0;
            })
            return boxdetail            
        },
        styleobj(a){
            if(a.minus){
                return {background: '#FFC0CB'}
            }
        },
        search(bool){
            console.log(this.$store.state.polist.find(e => e.발주번호 == "99458684"))
            this.showspinner = true;
            this.totalpo = 0;
            this.totalcancel = 0;
            if(bool){
                this.showtable = true;
                this.showdetailtable = false;
            } else {
                this.showtable = false;
                this.showdetailtable = true;
            }
            setTimeout(() => {
                this.showlist = false;
                this.polists = [];
                if(this.$store.state.polist.length > 0){
                setTimeout(() => {
                    var arr = [];
                    var arrangecnt = 0;
                    var notarrangecnt = 0;
                    var overseascnt = 0;
                    var domesticcnt = 0;
                    var pstockcnt = 0;
                    var rstockcnt = 0;
                    if(this.selectoption == '발주일'){
                        this.polists = this.$store.state.polist.filter(e => this.timestamp(this.startdate) <= this.timestamp(e.발주일시.slice(0,10)) && this.timestamp(this.enddate + "T23:59:59") >= this.timestamp(e.발주일시.slice(0,10)));
                    } else {
                        this.polists = this.$store.state.polist.filter(e => this.timestamp(this.startdate) <= this.timestamp(e.입고예정일.slice(0,10)) && this.timestamp(this.enddate + "T23:59:59") >= this.timestamp(e.입고예정일.slice(0,10)));
                    }
                    this.polists.forEach(e => {
                        e.발주세부내역.forEach(ele => {
                            arrangecnt = 0;
                            notarrangecnt = 0;
                            overseascnt = 0;
                            domesticcnt = 0;
                            pstockcnt = 0;
                            rstockcnt = 0;
                            if (this.$store.state.stock.length != 0){
                                this.$store.state.stock.forEach(element => {
                                    if(element.바코드 == ele.바코드){
                                        if(ele.바코드 == 'R009851590039'){
                                            console.log(element)
                                        }
                                        if(element.배치발주번호 == e.발주번호){
                                            arrangecnt++
                                        } else {
                                            if(element.배치발주번호 == ''){
                                                if(element.stockinfo == 'stock') {
                                                    if(parseInt(ele.확정수량.toString().replaceAll(',','')) - arrangecnt - notarrangecnt - pstockcnt - rstockcnt > 0){
                                                        
                                                        if(element.site == 'cn'){
                                                            overseascnt++
                                                            notarrangecnt++
                                                        } else {
                                                            if(element.site == 'kr'){
                                                                domesticcnt++
                                                                notarrangecnt++
                                                            }
                                                        }
                                                    }
                                                } else {
                                                    if(element.stockinfo == 'Pstock') {
                                                        pstockcnt++
                                                    } else {
                                                        if(element.stockinfo == 'Rstock') {
                                                            if(parseInt(ele.확정수량.toString().replaceAll(',','')) - arrangecnt - notarrangecnt - pstockcnt - rstockcnt > 0){
                                                                rstockcnt++
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                })
                            }
                            
                            ele.발주수량 = parseInt(ele.발주수량.toString().replaceAll(',',''));
                            ele.입고수량 = parseInt(ele.입고수량.toString().replaceAll(',',''));
                            if(isNaN(ele.입고수량)){
                                ele.입고수량 = 0;
                            }
                            ele.확정수량 = parseInt(ele.확정수량.toString().replaceAll(',',''));
                            ele.배치수량 = arrangecnt;
                            ele.미배치재고수량 = notarrangecnt;
                            ele.국내재고 = domesticcnt;
                            ele.해외재고 = overseascnt;
                            ele.구매완료수량 = pstockcnt;
                            ele.회송예정수량 = rstockcnt;
                            ele.사입재고수량 = 0;
                            if(ele.바코드 == 'R009851590039'){
                                console.log(ele)
                            }
                            if(ele.확정수량 - arrangecnt - notarrangecnt - pstockcnt - rstockcnt > 0){
                                this.$store.state.productdb.some(element => {
                                    if(ele.바코드 == element.바코드 && element.구매링크 == '사입'){
                                        ele.사입재고수량 = ele.확정수량 - arrangecnt - notarrangecnt - pstockcnt - rstockcnt;
                                        return (ele.바코드 == element.바코드 && element.구매링크 == '사입')
                                    }
                                })
                            }
                            ele.구매필요수량 = ele.확정수량 - arrangecnt - notarrangecnt - pstockcnt - rstockcnt - ele.사입재고수량;
                            // console.log(this.$store.state.productdb.find(element => element.바코드 == ele.바코드))
                            // console.log(ele.바코드)
                            // console.log(e.발주번호)
                            if(this.$store.state.productdb.find(element => element.바코드 == ele.바코드) && this.$store.state.productdb.find(element => element.바코드 == ele.바코드).매입가){
                                ele.공급가 = this.$store.state.productdb.find(element => element.바코드 == ele.바코드).매입가
                            } else {
                                ele.공급가 = 0
                            }
                            
                        })
                    })

                    this.polists.sort(function(a,b){
                        if (a.발주일시 < b.발주일시){
                            return 1;
                        }
                        if (a.발주일시 > b.발주일시){
                            return -1;
                        }
                        return 0;
                    })
                    // console.log(this.발주번호.length)
                    
                    if (this.발주번호.length > 0) {
                        this.polists.forEach((e,i) => {
                            if(e.발주번호 == this.발주번호){
                                arr.push(e)
                            }
                            if(i == this.polists.length - 1){
                                this.polists = arr;
                                this.발주번호 = '';
                            }
                        })
                    } else {
                        if (this.SKUID.length > 0) {
                            this.polists.forEach((e,i) => {
                                e.발주세부내역.forEach(ele => {
                                    if(ele.SKUID == this.SKUID){
                                        arr.push(e)
                                    }
                                    if(i == this.polists.length - 1){
                                        this.polists = arr;
                                        this.SKUID = '';
                                    }                                
                                })
                            })
                        } else {
                            if (this.바코드.length > 0) {
                                this.polists.forEach((e,i) => {
                                    e.발주세부내역.forEach(ele => {
                                        if(ele.바코드 == this.바코드){
                                            arr.push(e)                              
                                        }
                                        if(i == this.polists.length - 1){
                                            this.polists = arr;
                                            this.바코드 = '';
                                        }                                 
                                    })
                                })
                            }
                        }
                    }

                    // if(this.checksstock){
                    //     this.polists = this.polists.filter(e => e.발주세부내역.some(ele => ele.사입재고수량 > 0));
                    // }

                    //모니터링
                    this.mototal = 0;
                    this.mostock = 0;
                    this.moconfirm = 0;
                    this.moaddstock = 0;
                    this.moastock = 0;
                    this.mopstock = 0;
                    this.morstock = 0;
                    this.mosstock = 0;
                    this.minusprofit = 0;
                    this.mocancel = 0;
                    this.polists.forEach(e => {
                        this.totalpo += parseInt(e.금액.replaceAll(',',''))
                        if(e.발주상태 == '발주취소'){
                            this.totalcancel += parseInt(e.금액.replaceAll(',',''))
                        }
                        e.발주세부내역.forEach(ele => {
                            this.mototal += ele.발주수량;
                            if(e.발주상태 == '발주취소'){
                                this.mocancel += ele.발주수량;
                            } else {
                                this.moconfirm += ele.확정수량;
                                this.mostock += ele.미배치재고수량;
                                this.moaddstock += ele.구매필요수량;
                                this.moastock += ele.배치수량;
                                this.mopstock += ele.구매완료수량;
                                this.morstock += ele.회송예정수량;
                                this.mosstock += ele.사입재고수량;
                                if(this.$store.state.productdb.find(element => element.바코드 == ele.바코드)){
                                    if(parseInt(ele.매입가.replaceAll(",","")) < parseInt(this.$store.state.productdb.find(element => element.바코드 == ele.바코드).매입가)){
                                        this.minusprofit++
                                    }
                                }
                                
                            }
                        })
                    })


                    this.searchresult = this.polists;

                    //console.log(this.polists)
                    this.showlist = true;
                }, 1);
                } else {
                    alert('발주내역이 없습니다.')
                }
                this.showspinner = false;
            }, 1);
            
        },
        showmototal(){
            this.showlist=false;
            setTimeout(() => {
                this.polists = this.searchresult
                this.showlist = true;
            }, 1);
        },
        minusprofitlist(){
            this.showlist = false;
            setTimeout(() => {
                
                this.polists = this.searchresult.filter(e => e.발주세부내역.some(ele => parseInt(ele.매입가.replaceAll(",","")) < parseInt(this.$store.state.productdb.find(element => element.바코드 == ele.바코드).매입가)));
                this.polists.forEach(e => {
                    e.발주세부내역.forEach(ele => {
                        if(parseInt(ele.매입가.replaceAll(",","")) < parseInt(this.$store.state.productdb.find(element => element.바코드 == ele.바코드).매입가)){
                            console.log(this.$store.state.productdb.find(element => element.바코드 == ele.바코드).매입가)
                            console.log(parseInt(this.$store.state.productdb.find(element => element.바코드 == ele.바코드).매입가))
                            console.log(ele.매입가.replaceAll(",",""))
                            console.log(parseInt(ele.매입가.replaceAll(",","")))
                            ele.minus = parseInt(this.$store.state.productdb.find(element => element.바코드 == ele.바코드).매입가) - parseInt(ele.매입가.replaceAll(",",""));
                            console.log(ele.minus)
                        }
                    })
                })
                console.log(this.polists)
                this.showlist = true;
            }, 1); 
        }, 
        showmocancel(){
            this.showlist=false;
            setTimeout(() => {
                this.polists = this.searchresult.filter(e => e.발주상태 == '발주취소');
                this.showlist = true;
            }, 1);
        },     
        showmosstock(){
            this.showlist=false;
            setTimeout(() => {
                
                this.polists = this.searchresult.filter(e => e.발주세부내역.some(ele => ele.사입재고수량 > 0));
                this.showlist = true;
            }, 1);
        },
        showmostock(){
            this.showlist=false;
            setTimeout(() => {
                this.polists = this.searchresult.filter(e => e.발주세부내역.some(ele => ele.미배치재고수량 > 0));
                this.showlist = true;
            }, 1);
        },
        showmoconfirm(){
            this.showlist=false;
            setTimeout(() => {
                this.polists = this.searchresult.filter(e => e.발주세부내역.some(ele => ele.확정수량 > 0));
                this.showlist = true;
            }, 1);
        },
        showmoaddstock(){
            this.showlist=false;
            setTimeout(() => {
                this.polists = this.searchresult.filter(e => e.발주세부내역.some(ele => ele.구매필요수량 > 0));
                this.showlist = true;
            }, 1);
        },
        showmoastock(){
            this.showlist=false;
            setTimeout(() => {
                this.polists = this.searchresult.filter(e => e.발주세부내역.some(ele => ele.배치수량 > 0));
                this.showlist = true;
            }, 1);
        }, 
        showmopstock(){
            this.showlist=false;
            setTimeout(() => {
                this.polists = this.searchresult.filter(e => e.발주세부내역.some(ele => ele.구매완료수량 > 0));
                this.showlist = true;
            }, 1);
        }, 
        showmorstock(){
            this.showlist=false;
            setTimeout(() => {
                this.polists = this.searchresult.filter(e => e.발주세부내역.some(ele => ele.회송예정수량 > 0));
                this.showlist = true;
            }, 1);
        }, 
		deepClone(obj) {
			if (obj === null || typeof obj !== "object") {
				return obj
			}

			const result = Array.isArray(obj) ? [] : {}

			for (let key of Object.keys(obj)) {
				result[key] = this.deepClone(obj[key])
			}

			return result
		},        
        timestamp(date){
            //inputdate = "yyyy-mm-dd"
            return new Date(date).getTime() / 1000
        },
        getTodaywith(){
            var date = new Date();
            var year = date.getFullYear();
            var month = ("0" + (1 + date.getMonth())).slice(-2);
            var day = ("0" + date.getDate()).slice(-2);

            return year + "-" + month + "-" + day;
        },   
        getdate(timestamp){
            var date = new Date(timestamp);
            var year = date.getFullYear();
            var month = ("0" + (1 + date.getMonth())).slice(-2);
            var day = ("0" + date.getDate()).slice(-2);

            return year + "-" + month + "-" + day;
        },              
        MenuUpdate() {
            console.log(this.picked)
            if (this.picked == "radio1") {
                this.menuint = this.menuDefault;
            } else {
                if (this.picked == "radio2") {
                    this.menuint = this.menuDetail;
                } else {
                    this.menuint = this.menuSales;
                }
            }
        },
        MenuUpdate1() {
            console.log(this.picked1)
            if (this.picked1 == "radio1") {
                this.showdetailtable = false;
                this.showtable = true;
            } else {
                this.showdetailtable = true;
                this.showtable = false;
            }
        },
        changedate(){
            var now
            var target
            if(this.selectdate == 'today'){
                this.startdate = this.getTodaywith();
                this.enddate = this.getTodaywith();
            } else {
                if(this.selectdate == 'week'){
                    now = new Date(this.getTodaywith());
                    target = now - 1000*60*60*24*7
                    
                    this.startdate = this.getdate(target);
                    this.enddate = this.getTodaywith();
                } else {
                    if(this.selectdate == 'month'){
                    now = new Date(this.getTodaywith());
                    target = now - 1000*60*60*24*30
                    this.startdate = this.getdate(target);
                    this.enddate = this.getTodaywith();
                    }
                }
            }
        },
        modalID(index) {
            // console.log(index);
            return 'modal' + index
        },
        // test(index) {
        //     // console.log(index)
        //     // console.log(this.modalID(index))
        // },
        downloadpolist() {
            function getToday(){
                var date = new Date();
                var year = date.getFullYear();
                var month = ("0" + (1 + date.getMonth())).slice(-2);
                var day = ("0" + date.getDate()).slice(-2);

                return year + month + day;
            }
            var detailpolists = [];
            var n = 0;
            function mergeObj(obj1, obj2) {
                const newObj = {};
                for (let att in obj1) { 
                    newObj[att] = obj1[att]; 
                }
                for(let att in obj2)  {
                    newObj[att] = obj2[att];
                }
                return newObj;
            }

            // console.log(this.polists.filter(e => e.발주번호 == '44269950'))
            for (let i=0;i<this.polists.length;i++) {
                for (let j=0;j<this.polists[i].발주세부내역.length;j++) {
                    var a = this.polists[i];
                    var b = a.발주세부내역[j];
                    // console.log(a)
                    // console.log(b)
                    detailpolists[n] = mergeObj(a,b);
                    if(detailpolists[n].발주번호 == '44269950'){
                        console.log(detailpolists[n])
                    }
                    n++
                }
            }

            const workBook = XLSX.utils.book_new()
            const workSheet1 = XLSX.utils.json_to_sheet(this.polists)
            const workSheet2 = XLSX.utils.json_to_sheet(detailpolists)
            XLSX.utils.book_append_sheet(workBook, workSheet1, '발주내역')
            XLSX.utils.book_append_sheet(workBook, workSheet2, '발주세부내역')
            XLSX.writeFile(workBook, '발주내역' + '_' + getToday() + '.xlsx')
        },
        downloadpoconfirm(){
            if(this.polists.filter(e => e.발주상태 == '거래처확인요청').length == 0){
                alert('다운로드할 내역이 없습니다.')
                return
            }
            var detailpolists = [];
            var n = 0;
            function mergeObj(obj1, obj2) {
                const newObj = {};
                for (let att in obj1) { 
                    newObj[att] = obj1[att]; 
                }
                for(let att in obj2)  {
                    newObj[att] = obj2[att];
                }
                return newObj;
            }

            for (let i=0;i<this.polists.length;i++) {
                for (let j=0;j<this.polists[i].발주세부내역.length;j++) {
                    var a = this.polists[i];
                    var b = a.발주세부내역[j];
                    // console.log(a)
                    // console.log(b)
                    detailpolists[n] = mergeObj(a,b);
                    n++
                }
            }

            // console.log(detailpolists)

            var arr = detailpolists.filter(e => e.발주상태 == "거래처확인요청")
            var ar = [];
            var obj= {};
            var confirmcnt = 0;
            if(this.confirmpo == 'total'){
                arr.forEach(e => {
                    obj = {
                        발주번호 : e.발주번호,
                        물류센터 : e.발주센터,
                        입고유형 : e.발주운송,
                        발주상태 : e.발주상태,
                        상품번호 : e.SKUID,
                        상품바코드 : e.바코드,
                        상품이름 : e.상품명,
                        발주수량 : e.발주수량,
                        확정수량 : e.확정수량,
                        유통기한 : '',
                        제조일자 : '',
                        생산년도 : '',
                        납품부족사유 : this.reason,
                    }
                    ar.push(obj)
                })
            } else {
                if(this.confirmpo == 'stockkr'){
                    arr.forEach(e => {
                        if(e.발주수량 > this.$store.state.stock.filter(ele => ele.바코드 == e.바코드 && ele.stockinfo == 'stock' && ele.site == 'kr').length){
                            confirmcnt = this.$store.state.stock.filter(ele => ele.바코드 == e.바코드 && ele.stockinfo == 'stock' && ele.site == 'kr').length;
                        } else {
                            confirmcnt = e.발주수량;
                        }
                        obj = {
                            발주번호 : e.발주번호,
                            물류센터 : e.발주센터,
                            입고유형 : e.발주운송,
                            발주상태 : e.발주상태,
                            상품번호 : e.SKUID,
                            상품바코드 : e.바코드,
                            상품이름 : e.상품명,
                            발주수량 : e.발주수량,
                            확정수량 : confirmcnt,
                            유통기한 : '',
                            제조일자 : '',
                            생산년도 : '',
                            납품부족사유 : this.reason,
                        }
                        ar.push(obj)
                    })
                } else {
                    arr.forEach(e => {
                        if(e.발주수량 > this.$store.state.stock.filter(ele => ele.바코드 == e.바코드 && ele.stockinfo == 'stock').length){
                            confirmcnt = this.$store.state.stock.filter(ele => ele.바코드 == e.바코드 && ele.stockinfo == 'stock').length;
                        } else {
                            confirmcnt = e.발주수량;
                        }
                        obj = {
                            발주번호 : e.발주번호,
                            물류센터 : e.발주센터,
                            입고유형 : e.발주운송,
                            발주상태 : e.발주상태,
                            상품번호 : e.SKUID,
                            상품바코드 : e.바코드,
                            상품이름 : e.상품명,
                            발주수량 : e.발주수량,
                            확정수량 : confirmcnt,
                            유통기한 : '',
                            제조일자 : '',
                            생산년도 : '',
                            납품부족사유 : this.reason,
                        }
                        ar.push(obj)
                    })
                }
            }

            const workBook = XLSX.utils.book_new()
            const workSheet1 = XLSX.utils.json_to_sheet(ar)
            XLSX.utils.book_append_sheet(workBook, workSheet1, '상품목록')
            XLSX.writeFile(workBook, 'PO_FOR_CONFIRM.xlsx')


        }
    }
}
</script>
<style>
.modal-dialog {
    max-width: 90%;
    position: absolute; 
    
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
/* ::v-deep .modal-class {
    max-width: 100%;
    position: absolute; 
    
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
} */


.table .fixed-header {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

.table .fixed-header thead {
  display: block;
}

.table .fixed-header tbody {
  display: block;
  height: 800px; /* 테이블 본문의 최대 높이를 지정하세요 */
  overflow-y: scroll; /* 세로 스크롤을 표시할지 여부를 설정하세요 */
}

</style>

